<template>
  <div>
    <div class="wrap-error" v-if="invalid">
      <p>
        アクティベートキーが無効です。<br />
        メールアドレス登録時のメールに記載されたURLから再度アクセスするか、ログインをお試しください。
      </p>
    </div>

    <form
      class="form_login form"
      autocomplete="off"
      v-if="!invalid">
      <div>SIGN UP</div>

      <div v-if="!flag.sended">
        <div>
          <input
            id="password"
            type="password"
            name="password"
            placeholder="password"
            v-model.trim="$v.password.$model"
            v-bind:class="{ input_error: validationFlags.password }"
            v-on:input="invalidsCheck('password', $v.password.$invalid)">
          <span class="slide-in"></span>
        </div>
        <p
          v-if="$v.password.$dirty && $v.password.required.$invalid">
          パスワードを入力してください</p>
        <p
          v-if="$v.password.$dirty && $v.password.minLength.$invalid">
          パスワードは8文字以上で設定してください</p>

        <div
          v-if="!flag.sended">
          <button
            @click="submit"
            v-bind:disabled="!submitFlag">
          SEND</button>
        </div>

      </div>
      <div
        v-if="flag.sended && flag.registed">
        <p>
          パスワードを登録しました。<br>
          <router-link :to="'/login/?isRegist=true'">ログインページ</router-link>よりログインしてください。
        </p>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import cf from '@/mixins/commonFunctions.js';


export default {
  name: 'SignupPassword',
  mixins: [cf],
  components: {
  },
  data() {
    return {
      validationFlags: {
        password: false,
      },
      // 無効フラグ
      invalids: {
        password: true,
        minLength: true,
      },
      // パラメータなし等で無効
      invalid: true,
      activatekey: null,
      flag: {
        sended: false,
        registed: false,
      },
    };
  },
  created() {
    // パラメータが含まれているか確認
    const query = this.$route.query;
    if (query.activate) {
      this.invalid = false;
      this.activatekey = query.activate;
    }
  },
  setup() {
    const password = ref('');

    const rules = {
      // パスワードは必須で8文字以上
      password: {
        required,
        minLength: minLength(8),
      },
    };

    const $v = useVuelidate(rules, { password });
    return { password, $v };
  },
  computed: {
    ...mapState(['user', 'page']),
    submitFlag() {
      // 無効フラグが全てfalseならばtrueを返却
      return !this.invalids.password;
    },
  },
  methods: {
    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },

    /** サブミット */
    submit(e) {
      e.preventDefault();

      // validateチェックは入力時に行われてる
      const data = {
        flag: 1,
        password: this.password,
        activatekey: this.activatekey,
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/create/password',
        data,
      })
        .then((response) => {
          const resData = response.data;
          if (resData.registed) this.flag.registed = true;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
          this.invalid = true;
        })
        .finally(() => {
          this.flag.sended = true;
        });
    },
  },
};
</script>

<style lang="scss">
</style>
