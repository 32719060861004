/**
* ページ情報管理
*/

const actions = {
  /** ページ情報のセット */
  setPageData({ commit }, args) {
    commit('setPageData', args);
  },
  resetPageData({ commit }) {
    commit('resetPageData');
  },
};


const mutations = {
  setPageData(state, args) {
    if (args?.title) state.title = args.title;
    if (args?.description) state.description = args.description;
    if (args?.headColor) state.headColor = args.headColor;
  },
  resetPageData(state) {
    state.title = null;
    state.description = null;
    state.headColor = null;
  },
};

const state = {
  title: null,
  description: null,
  headColor: null,
};


export default {
  namespaced: true,
  actions,
  mutations,
  state,
};
