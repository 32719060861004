<template>
  <li>
    <component
      :is="link ? 'a' : 'div'"
      :href="link ? link : ''"
      :target="target ? target : '_self'">
      <Bg :bgcolor="bgcolor" :radius="28">
        <slot name="head"/>
        <div :class="[s.wrap, s[size]]">
          <div :class="s.main">
            <slot name="content" />
          </div>
          <div
            :class="[s.img_wrap, s[size]]"
            v-if="img || requireImg">
            <div
              :class="[s.img, img ? '' : s.dammy]"
              :style="`background-image: url(${img || '/images/info/dammy.png'});`" />
          </div>
        </div>
      </Bg>
    </component>
  </li>
</template>

<script>
import {
  Bg,
} from '@/components/parts';

export default {
  name: 'simple-list-item',
  props: {
    img: {
      type: String,
    },
    requireImg: { // 画像ない場合ダミー表示するか
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'sm',
      validator: (value) => ['sm', 'lg'].includes(value),
    },
    bgcolor: {
      type: String,
      default: 'none',
      validator: (value) => ['green', 'purple', 'none'].includes(value),
    },
    link: {
      type: String,
    },
    target: {
      type: String,
    },
  },
  components: {
    Bg,
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  display: flex;
  align-items: stretch;
  padding: 8px 0;
  color: var(--label-primary);
  &.lg {
    padding: 32px;
  }
}
.main {
  flex: 1;
}
.img {
  &_wrap {
    &.lg {
      width: 33%;
      max-width: 240px;
      margin: 0 0 7px 32px;
    }
    width: 25%;
    max-width: 116px;
    margin-left: 16px;

    @include smView {
      width: 116px;
    }
  }
  width: 100%;
  padding-top: calc(100% * (2 / 3));
  border-radius: 4px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  &.dammy {
    background-size: contain;
  }
}
</style>
