<template>
  <div
    v-if="item"
    :class="s.wrap">
    <div v-if="item.medias && item.medias.length" :class="s.img_wrap">
      <div
        v-for="(media, i) in item.medias"
        :key="i"
        :class="[s.img, i > 0 ? s.mgn : '']"
        :style="`background-image: url(${media.url})`"/>
    </div>
    <div :class="s.content">
      <div>
        <p
          v-if="item.title"
          :class="s.title"
          class="n2br"
          v-html="item.title"/>
        <p
          v-if="item.description || item.summary"
          v-html="item.description || item.summary"
          :class="s.description" />
      </div>
      <FlexList
        :spaceX="12"
        :spaceY="12"
        :wrap="true"
        v-if="item.buttons?.length || item.link"
        :class="s.btns">
        <!-- 初期実装 -->
        <li v-for="(btn, i) in item.buttons" :key="i">
          <basic-btn
            :size="'sm'"
            :width="'fit-content'"
            :tag="btn.link ? 'a' : 'button'"
            :link="btn.link ? btn.link : ''"
            :openOtherTab="btn.openOtherTab">{{ btn.label }}</basic-btn>
        </li>
        <!-- supportsはlinkにシリアライズされた配列が渡ってくる -->
        <li v-for="(btn, i) in adjustedLink(item.link)" :key="i">
          <basic-btn
            :size="'sm'"
            :width="'fit-content'"
            :tag="btn.value ? 'a' : 'button'"
            :link="btn.value"
            :openOtherTab="true">{{ btn.label }}</basic-btn>
        </li>
      </FlexList>
    </div>
  </div>
</template>

<script>
import FlexList from './FlexList.vue';
import BasicBtn from './BasicBtn.vue';

export default {
  name: 'content-list-frame',
  props: {
    item: {
      type: Object,
    },
  },
  components: {
    BasicBtn,
    FlexList,
  },
  methods: {
    adjustedLink(str) {
      try {
        return JSON.parse(str);
      } catch {
        return [];
      }
    },
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  display: flex;
  flex-direction: row-reverse;
}
.content {
  flex: 1;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  text-align: start;

  .title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 12px;
  }

  .description {
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .btns {
    margin-top: 32px;
  }
}
.img {
  &_wrap {
    flex-shrink: 0;
    margin-left: 24px;
    width: 30%;
    max-width: 240px;
  }

  width: 100%;
  padding-top: calc(100% * 2 / 3);
  background-size: cover;
  background-position: center;
  border-radius: 14px;

  &.mgn {
    margin-top: 16px;
  }
}

@include smView {
  .wrap {
    display: block;
  }
  .content {
    margin-top: 16px;
    .btns {
      margin-top: 16px;
    }
  }
  .img {
    &_wrap {
      margin-left: 0;
      width: 100%;
      max-width: 100%;
    }
  }
}
</style>
