<template>
  <li :style="style">
    <Bg :bgcolor="bgcolor" :radius="28">
      <div :class="s.inner">
        <slot/>
      </div>
    </Bg>
  </li>
</template>

<script>
import Bg from './Bg.vue';

export default {
  name: 'bg-list-item',
  components: {
    Bg,
  },
  props: {
    bgcolor: {
      type: String,
      default: 'none',
      validator: (value) => ['green', 'purple', 'red', 'gradation', 'gray', 'none'].includes(value),
    },
    pdg: {
      type: Number,
      default: 32,
    },
    smPdg: {
      type: Number,
      default: 20,
    },
  },
  computed: {
    style() {
      return {
        '--pdg': `${this.pdg}px`,
        '--pdg-sm': `${this.smPdg}px`,
      };
    },
  },
};
</script>

<style lang="scss" module="s">
.inner {
  padding: var(--pdg);
}
@include smView {
  .inner {
    padding: var(--pdg-sm);
  }
}
</style>
