<template>
  <div>
    <Loader
      v-if="!items.length"
      :flag="true"/>
    <div v-if="items.length">
      <SectionContainer v-if="!flag.sended">
        <SectionFrame :smMarginTop="80" :marginTop="32">
          <ul :class="s.list">
            <li
              v-for="item in items"
              :key="item.name">
              <div :class="s.label">{{ item.label }}<p v-if="item.required" :class="s.required">*</p></div>
              <div :class="s.form">
                <input
                  v-if="item.tag === 'input'"
                  :type="item.type"
                  v-model="values[item.name]">
                <textarea
                  :rows="item.rows"
                  v-else-if="item.tag === 'textarea'"
                  v-model="values[item.name]"/>
                <select
                  v-if="item.tag === 'select'"
                  class="form-select"
                  v-model="values[item.name]">
                  <option
                    v-for="option in item.options"
                    :key="option.value"
                    :value="option.value">{{ option.label }}</option>
                </select>
              </div>
              <p :class="s.assistance" v-if="item.note">{{ item.note }}</p>
            </li>
          </ul>
          <spacer :y="3"/>
          <div v-if="helper.master.contact">
            <div :class="s.label">個人情報の取扱いへの同意</div>
            <div :class="s.policy_box">
              <Policy :policy="helper.master.contact.privacyPolicy"/>
            </div>
            <input type="checkbox" v-model="policyChecked" name="policy" id="policy" style="display: none">
            <label for="policy" :class="[s.check, policyChecked ? s.checked : '']">同意する</label>
          </div>
          <spacer :y="10"/>
          <FlexList :align="'center'">
            <basic-btn @click="submit" :disabled="!readySubmit">送信</basic-btn>
          </FlexList>
        </SectionFrame>
      </SectionContainer>
      <SectionContainer v-else>
        <SectionFrame :smMarginTop="80" :marginTop="32">
          <p :class="s.sended">お問い合わせを受け付けました。<br>移住コーディネーターが内容を確認後、順次ご連絡させていただきます。</p>
        </SectionFrame>
      </SectionContainer>
    </div>
    <spacer :y="15"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  SectionContainer,
  SectionFrame,
  Spacer,
  FlexList,
  BasicBtn,
  Policy,
  Loader,
} from '@/components/parts';

export default {
  name: 'contact',
  components: {
    SectionContainer,
    SectionFrame,
    Spacer,
    FlexList,
    BasicBtn,
    Policy,
    Loader,
  },
  data() {
    return {
      flag: {
        sended: false,
      },
      items: [],
      values: {},
      policyChecked: false,
    };
  },
  computed: {
    ...mapState(['helper']),
    helperState() {
      return this.helper.master.contact;
    },
    requiredLabels() {
      return this.items?.filter((item) => item.required).map((row) => row.name) || [];
    },
    readySubmit() {
      const valueLabels = Object.keys(this.values);
      const readyValues = Boolean(valueLabels.length && this.requiredLabels.every((requiredLabel) => valueLabels.includes(requiredLabel) && this.values[requiredLabel]));
      return readyValues && this.policyChecked;
    },
  },
  watch: {
    helperState() {
      this.initItems();
    },
  },
  created() {
    this.initItems();
  },
  methods: {
    /** ローディング開始 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loading/showModal', args);
    },

    /** ローディング終了 */
    hideLoading() {
      this.$store.dispatch('modal/loading/hideModal', null);
    },
    initItems() {
      this.items = this.helper?.master?.contact?.formItems || [];
    },
    submit() {
      if (!this.readySubmit) return;
      this.showLoading();

      this.axios({
        method: 'POST',
        url: '/v1/contact/create',
        data: this.values,
      })
        .then((response) => {
          const res = response.data;
          if (res.isAccepted) {
            this.values = {};
            this.policyChecked = false;
            this.flag.sended = true;
          }
        })
        .catch((error) => {
          alert('お問い合わせの送信に失敗しました。恐れ入りますが再度お試しください。');
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>

<style lang="scss" module="s">
.list {
  > li {
    &:not(:first-child) {
      margin-top: 32px;
    }
  }
}
.label {
  font-weight: 700;
  margin-bottom: 4px;
  display: flex;
}
.form {
  > * {
    width: 100%;
    padding: 12px;
    outline: none;
    border-radius: 0;
    border: 1px solid #dee2e6;
  }
}
.assistance {
  font-size: 12px;
  color: var(--label-gray);
}
.required {
  margin-left: 5px;
  color: var(--red-main);
}
.policy_box {
  padding: 16px;
  height: 300px;
  border: 1px solid #dee2e6;
  overflow-y: scroll;
}
.check {
  position: relative;
  padding-left: 28px;
  margin-top: 12px;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    width: 16px;
    height: 16px;
    background-color: var(--surface-black);
    border: 1px solid var(--border-point-gray);
    border-radius: 4px;
  }
  &::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 8px;
    width: 8px;
    height: 16px;
    transform: rotate(50deg);
    border-right: 3px solid #888888;
    border-bottom: 3px solid #888888;
    opacity: 0;
  }
  &.checked {
    &::after {
      opacity: 1;
    }
  }
}
.sended {
  text-align: center;
}
</style>
