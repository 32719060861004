<template>
  <div :class="s.container">
    <slot />
  </div>
</template>

<style lang="scss" module="s">
.container {
  display: flex;
  flex-flow: column;
  align-items: center;
}
</style>
