<template>
  <div>
    <SimpleList :class="s.topics" :margin="0">
      <li v-for="item in items" :key="item.id">
        <div
          :class="s.title"
          class="n2br"
          v-html="item.title"/>
        <basic-btn
          :class="s.btn"
          :type="'bdr'"
          :size="'sm'"
          :tag="'a'"
          :link="`/topics/${item.slug}/`"
          :width="'fit-content'">詳しく見る</basic-btn>
      </li>
    </SimpleList>
    <spacer :y="5"/>
    <FlexList
      v-if="showPageLink && detailPageLink"
      :align="'center'">
      <basic-btn
        :tag="'a'"
        :link="detailPageLink.path">「{{ detailPageLink.label }}」ページへ</basic-btn>
    </FlexList>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import axios from '@/plugins/axios.ts'
import SimpleList from './SimpleList.vue';
import BasicBtn from './BasicBtn.vue';
import FlexList from './FlexList.vue';
import Spacer from './Spacer.vue';

export default {
  name: 'topic-list',
  components: {
    SimpleList,
    BasicBtn,
    FlexList,
    Spacer,
  },
  props: {
    limit: {
      type: Number,
      default: 99999999,
    },
    showPageLink: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    ...mapState(['helper']),
    detailPageLink() {
      const detailPages = [
        {
          name: 'kaigyou-topics',
          path: '/kaigyou/',
          label: '店舗開業・起業のすすめ',
        },
        {
          name: 'sumu-topics',
          path: '/sumu/',
          label: '住む',
        },
        {
          name: 'hataraku-topics',
          path: '/hataraku/',
          label: '働く',
        },
        {
          name: 'kosodate-topics',
          path: '/kosodate/',
          label: '育てる',
        },
      ];
      const page = this.$route.meta ? detailPages.find((p) => p.name === this.$route.meta.name) : null;
      return page;
    },
  },
  created() {
    this.get();
  },
  methods: {
    get() {
      const params = { limit: this.limit };

      if (this.type) {
        params.category = this.type;
        params.orderBy = 'order';
        params.order = 'asc';
      }

      this.axios({
        method: 'GET',
        url: '/v1/topic/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.items = res.list.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" module="s">
.topics {
  padding: 32px;
  width: 100%;
  > li {
    padding: 28px 0;
    display: flex;
    align-items: center;
    width: 100%;
    .title {
      font-weight: 700;
      font-size: 20px;
      flex: 1;
    }
    .btn {
      flex-shrink: 0;
      margin-left: 12px;
    }
  }

  @include smView {
    padding: 20px 32px 32px;
    > li {
      padding: 12px 0;
      .title {
        font-size: 14px;
      }
    }
  }
}
</style>
