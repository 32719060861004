<template>
  <div :class="s.wrap" :style="style">
    <div :class="s.center">
      <p
        v-if="title"
        :class="s.title"
        class="n2br"
        v-html="title"/>
      <p
        v-if="description"
        :class="[s.description, s[descriptionSize]]"
        class="n2br"
        v-html="description"/>
    </div>
    <div :class="s.content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'section-frame',
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    descriptionSize: {
      type: String,
      default: 'sm',
      validator: (value) => ['sm', 'lg'].includes(value),
    },
    marginTop: {
      type: Number,
      default: 160,
    },
    smMarginTop: {
      type: Number,
      default: 120,
    },
    titleBottomMargin: {
      type: Number,
      default: 32,
    },
    smTitleBottomMargin: {
      type: Number,
      default: 24,
    },
    maxWidth: {
      type: Number,
      default: 800,
    },
    titleSize: {
      type: Number,
      default: 28,
    },
    smTitleSize: {
      type: Number,
      default: 24,
    },
    titleOpacity: {
      type: Number,
      default: 100,
    },
  },
  computed: {
    style() {
      return {
        '--margin-top': `${this.marginTop}px`,
        '--margin-top-sm': `${this.smMarginTop}px`,
        '--max-width': `${this.maxWidth}px`,
        '--title-font-size': `${this.titleSize}px`,
        '--title-font-size-sm': `${this.smTitleSize}px`,
        '--title-opacity': `${this.titleOpacity / 100}`,
        '--title-bottom-margin': `${this.titleBottomMargin}px`,
        '--title-bottom-margin-sm': `${this.smTitleBottomMargin}px`,
      };
    },
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  width: 100%;
  max-width: var(--max-width);
  margin-top: var(--margin-top);
  padding: 0 20px;
  @include smView {
    margin-top: var(--margin-top-sm);
  }
}
.center {
  text-align: center;
}
.title {
  font-size: var(--title-font-size);
  opacity: var(--title-opacity);
  font-weight: 700;
  margin-bottom: var(--title-bottom-margin);
  @include smView {
    font-size: var(--title-font-size-sm);
    margin-bottom: var(--title-bottom-margin-sm);
  }
}
.description {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 16px;
  margin-bottom: 40px;
  &.lg {
    font-size: 20px;
  }
}

.content {
  overflow: visible;
}

@include smView {
  .description {
    font-size: 14px;
    margin-bottom: 32px;
    &.lg {
      font-size: 14px;
    }
  }
}
</style>
