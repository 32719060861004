<template>
  <div :class="s.wrap">
    <ul
      v-if="items.length"
      :class="s.items">
      <li
        v-for="(item, i) in items"
        :key="i"
        :class="[
          i === 0 ? s.isActive : '',
        ]"
        @click="jump2Section(item.name)"
      >{{ item.label }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'menu-bar',
  data() {
    return {
      type: null,
      pages: [
        {
          path: '/shien',
          items: [
            {
              name: 'step',
              label: '移住ステップ',
            },
            {
              name: 'window',
              label: '支援窓口',
            },
            {
              name: 'support',
              label: '支援制度',
            },
            {
              name: 'simulation',
              label: 'シミュレーション',
            },
            {
              name: 'interview',
              label: '移住者インタビュー',
            },
          ],
        },
        {
          path: '/kaigyou',
          items: [
            {
              name: 'charm',
              label: '魅力',
            },
            {
              name: 'topics',
              label: 'お役立ちトピックス',
            },
            {
              name: 'empty-shop',
              label: '空き店舗紹介',
            },
            {
              name: 'organization',
              label: '支援機関',
            },
            {
              name: 'support',
              label: '支援制度',
            },
            {
              name: 'interview',
              label: '移住者インタビュー',
            },
          ],
        },
        {
          path: '/about',
          items: [
            {
              name: 'about',
              label: '基本情報',
            },
            {
              name: 'charm',
              label: '桐生暮らしの魅力',
            },
            {
              name: 'instagram',
              label: 'instagramで見る桐生市',
            },
            {
              name: 'movie',
              label: '動画で見る桐生市',
            },
            // {
            //   name: 'media',
            //   label: 'メディアで見る桐生',
            // },
            {
              name: 'interview',
              label: '移住者インタビュー',
            },
          ],
        },
        {
          path: '/sumu',
          items: [
            {
              name: 'merit',
              label: '魅力',
            },
            {
              name: 'topics',
              label: 'お役立ちトピックス',
            },
            {
              name: 'find-house',
              label: '住まいを探す',
            },
            {
              name: 'support',
              label: '支援制度',
            },
            {
              name: 'interview',
              label: '移住者インタビュー',
            },
          ],
        },
        {
          path: '/hataraku',
          items: [
            {
              name: 'charm',
              label: '魅力',
            },
            {
              name: 'topics',
              label: 'お役立ちトピックス',
            },
            {
              name: 'find-work',
              label: '多様な働き方',
            },
            {
              name: 'support',
              label: '支援制度',
            },
            {
              name: 'interview',
              label: '移住者インタビュー',
            },
          ],
        },
        {
          path: '/kosodate',
          items: [
            {
              name: 'charm',
              label: '魅力',
            },
            {
              name: 'topics',
              label: 'お役立ちトピックス',
            },
            {
              name: 'education',
              label: '桐生ならではの教育',
            },
            {
              name: 'various-education',
              label: '新たな学びの場',
            },
            {
              name: 'support',
              label: '支援制度・遊べる施設',
            },
            {
              name: 'interview',
              label: '移住者インタビュー',
            },
          ],
        },
      ],
    };
  },
  computed: {
    items() {
      const page = this.pages.find((p) => this.$route.path.includes(p.path));
      return page?.items || [];
    },
  },
  methods: {
    jump2Section(name) {
      this.$router.replace(`${this.$route.path}#${name}`);
      const elem = document.getElementById(name);
      if (elem) {
        elem.scrollIntoView();
      }
    },
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  display: flex;
  justify-content: center;
}
.items {
  display: flex;
  padding: 28px 48px;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  border-radius: 100px;
  > li {
    font-size: 14px;
    color: #000000;
    opacity: .5;
    cursor: pointer;
    white-space: nowrap;

    &:not(:first-child) {
      margin-left: 48px;
    }

    &.isActive {
      opacity: 1;
      font-weight: 700;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        $wh: 8px;
        left: calc(-6px - $wh);
        top: 50%;
        transform: translate(0%, -50%);
        border-radius: $wh;
        width: $wh;
        height: $wh;
        background-color: var(--label-primary);
      }
    }
  }
}
@include smView {
  .wrap {
    width: 100%;
  }
  .items {
    margin: 0 auto;
    flex-flow: column;
    align-items: center;
    border-radius: 28px;
    > li {
      display: inline-block;
      &:not(:first-child) {
        margin-top: 12px;
        margin-left: 0;
      }
    }
  }
}
</style>
