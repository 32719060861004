<template>
  <div>
    <TopicList :type="type" />
  </div>
</template>

<script>
import {
  TopicList,
} from '@/components/parts';

export default {
  name: 'topics-detail',
  components: {
    TopicList,
  },
  data() {
    return {};
  },
  computed: {
    type() {
      return this.$route.path.replace(/\/|topics/g, '') || '';
    },
  },
};
</script>

<style lang="scss" module="s">

</style>
