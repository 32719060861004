<template>
  <div>
    <SectionContainer>
      <SectionFrame :marginTop="80">
        <InfoList />
      </SectionFrame>
    </SectionContainer>
    <Spacer :y="15"/>
  </div>
</template>

<script>
import {
  SectionContainer,
  SectionFrame,
  InfoList,
  Spacer,
} from '@/components/parts';

export default {
  name: 'news',
  components: {
    SectionContainer,
    SectionFrame,
    InfoList,
    Spacer,
  },
};
</script>

<style lang="scss" module="s">

</style>
