<template>
  <div :class="s.wrap">
    <div id="show-trigger">
      <ul :class="s.columns">
        <li
          v-for="n of colNum"
          :key="n"
          :class="[
            animation.includes('slide') ? s.slide_wrap : '',
            n % 2 === 1 ? s.odd : ''
          ]">
          <ul :class="s.images" v-for="l of animation.includes('slide') ? 3 : 1" :key="l">
            <li v-for="m of 5" :key="m">
              <component
                :is="suffledMedias[5 * (n - 1) + m - 1]?.link ? 'a' : 'div'"
                :href="suffledMedias[5 * (n - 1) + m - 1]?.link || ''"
                target="_blank"
                :class="[
                  s.img,
                  s[imgSize(n, m)],
                ]">
                <transition name="top-img">
                  <img
                    v-if="displayMediaIdxs.includes(5 * (n - 1) + m - 1)"
                    :src="suffledMedias[5 * (n - 1) + m - 1]?.media?.url" alt="">
                </transition>
              </component>
            </li>
          </ul>
        </li>
      </ul>
      <div :class="s.gradation" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'top-images',
  data() {
    return {
      displayMediaIdxs: [],
      stop: null,
      displaySize: 'lg',
      medias: [],
      animation: 'none',
    };
  },
  created() {
    this.getMedias();
  },
  mounted() {
    // storeにwindowサイズがセットされるまで待てないのでここでも判定（storeにセットされたらそちらを参照する）
    const windowWidth = window.innerWidth;
    if (windowWidth >= 1024) this.displaySize = 'lg';
    else if (windowWidth >= 767) this.displaySize = 'md';
    else if (windowWidth >= 375) this.displaySize = 'sm';
    else this.displaySize = 'xs';
  },
  computed: {
    ...mapState(['layout']),
    colNum() {
      const displaySize = this.layout.sizeChecked ? this.layout.displaySize : this.displaySize;
      return ['lg', 'md'].includes(displaySize) ? 5 : 3;
    },
    suffledMedias() {
      const array = this.medias;
      for (let i = (array.length - 1); i > 0; i -= 1) {
        // 0〜(i+1)の範囲で値を取得
        const r = Math.floor(Math.random() * (i + 1));
        // 要素の並び替えを実行
        const tmp = array[i];
        array[i] = array[r];
        array[r] = tmp;
      }
      // 25枚以下の時は複製して25枚以上にする
      if (array.length < 25 && array.length) {
        const count = Math.floor(25 / array.length);
        const raw = array;
        for (let i = 0; i < count; i += 1) {
          array.push(...raw);
        }
      }
      // 上限25枚
      const arrayLimited = array.filter((_, i) => i < 25);
      return arrayLimited;
    },
  },
  methods: {
    imgSize(n, m) {
      const sizes = {
        col1: ['md', 'lg', 'md', 'sm', 'lg'],
        col2: ['lg', 'sm', 'md', 'lg', 'md'],
        col3: ['md', 'md', 'lg', 'sm', 'lg'],
        col4: ['sm', 'lg', 'md', 'lg', 'md'],
        col5: ['md', 'lg', 'md', 'sm', 'lg'],
      };
      return sizes[`col${n}`][m - 1];
    },
    getMedias() {
      const params = {
        flags: [1],
      };
      this.axios({
        method: 'GET',
        url: '/v1/mainVisual/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.medias = res.mainVisuals.data;
          this.startAnimation();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        });
    },
    startAnimation() {
      this.animation = this.$route.query.animation || 'alpha';
      if (this.animation.includes('alpha')) {
        this.stop = setInterval(() => { this.setDisplayMediaIdx(); }, 200);
      } else {
        this.displayMediaIdxs = this.medias.map((_, i) => i);
      }
    },
    setDisplayMediaIdx() {
      const numTarget = 2;

      const targets = [];
      // targetに乱数（0 ~ this.medias.length - 1）を[numTarget]個格納
      for (let i = 0; i < numTarget; i += 1) {
        targets.push(Math.floor(Math.random() * (this.medias.length - 1)));
      }

      targets.forEach((target) => {
        const hiddenMedias = this.medias.map((_, i) => i).filter((_, i) => !this.displayMediaIdxs.includes(i));
        // targetに一番近いidxをdisplayMediasIdxsに格納
        if (hiddenMedias.length) {
          let showIdx = hiddenMedias[0];
          hiddenMedias.forEach((idx) => {
            const nowDif = Math.abs(showIdx - target);
            const thisDif = Math.abs(idx - target);
            if (nowDif > thisDif) showIdx = idx;
          });
          this.displayMediaIdxs.push(showIdx);
        }
      });

      if (this.displayMediaIdxs.length === this.medias.length) {
        clearInterval(this.stop);
        // setTimeout(() => { this.setDisplayMediaIdx = [] }, 1000)
      }
    },
  },
};
</script>

<style lang="scss" module="s">
.loader {
  min-height: calc(100vh - var(--top-head-height) + 27px);
}
.wrap {
  position: relative;
}

$pdg: 22px;
$pdg-sm: 6px;
.columns {
  display: flex;
  padding: 0 calc(100% * 10 / 1440);
  > li {
    width: 20%;
    padding: 0 $pdg;
    &.odd {
      margin-top: -24px;
    }
  }

  @include smView {
    > li {
      width: calc(100% / 3);
      padding: 0 $pdg-sm
    }
  }
}

.slide_wrap {
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 1200px;
  overflow: hidden;
  > ul {
    animation: slide-up 40s linear infinite;
  }
  &.odd {
    > ul {
      animation: slide-down 40s linear infinite;
    }
  }
}
@keyframes slide-down {
  0% { transform: translateY(0); }
  100% { transform: translateY(100%); }
}
@keyframes slide-up {
  0% { transform: translateY(0); }
  100% { transform: translateY(-100%); }
}

.images {
  > li {
    padding: $pdg 0;
    @include smView {
      padding: $pdg-sm 0;
    }
  }
  .img {
    width: 100%;
    border-radius: 28px;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
    isolation: isolate;
    display: block;

    &.sm {
      padding-top: 65%;
      @include smView {
        padding-top: 100%;
      }
    }
    &.md {
      padding-top: 100%;
    }
    &.lg {
      padding-top: 150%;
    }

    img {
      position: absolute;
      top: 0; left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.gradation {
  position: absolute;
  width: 100%;
  height: 30%;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 56.59%);
}
</style>
