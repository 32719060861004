<template>
  <div
    :class="[
      s.wrap,
      s[bgcolor],
    ]"
    :style="style" >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'content-box',
  props: {
    bgcolor: {
      type: String,
      default: 'none',
      validator: (value) => ['green', 'purple', 'red', 'gradation', 'gray', 'none'].includes(value),
    },
    radius: {
      type: Number,
      default: 0,
    },
    smRadius: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    style() {
      return {
        '--radius': `${this.radius}px`,
        '--radius-sm': `${this.calcSmRadius}px`,
      };
    },
    calcSmRadius() {
      if (this.smRadius) return this.smRadius;
      return this.radius;
    },
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  width: 100%;
  display: flex;
  flex-flow: column;
  border-radius: var(--radius);

  &.none {
    background-color: transparent;
  }
  &.purple {
    background-color: rgba(#A084AE, .1);
  }
  &.green {
    background-color: rgba(#AEC8C6, .1);
  }
  &.red {
    background-color: rgba(#E0BBB9, .1);
  }
  &.gray {
    background-color: var(--surface-field-gray);
  }
  &.gradation {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(135deg, #DDBEED 0%, #7B39BD 96.88%);
      opacity: .23;
      z-index: 0;
    }
  }
}

@include smView {
  .wrap {
    border-radius: var(--radius-sm);
  }
}
</style>
