<template>
  <div :class="s.wrap">
    <div :class="s.content">
      <img src="/images/logo.png" alt="logo">
      <p>ゆるやかに、つながり<br>ほどよく、むすばれる</p>
    </div>
    <img :class="s.arc" src="/images/layout/top_arc.svg">
  </div>
</template>

<style lang="scss" module="s">
.wrap {
  position: relative;
  z-index: 10;
}
.content {
  display: flex;
  align-items: center;
  flex-flow: column;
  background-color: #ffffff;
  width: 100%;
  height: var(--top-head-height);
  padding-top: 100px;
  padding-bottom: 53px;
  color: var(--brand-gray);
  img {
    width: 125px;
    margin-bottom: 28px;
  }
  p {
    font-size: 22px;
    letter-spacing: 4px;
    text-align: center;
    font-family: 'Zen Kaku Gothic New';
  }
}
.arc {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 120%;
}
</style>
