<template>
  <div>
    <SectionContainer id="merit">
      <SectionFrame
        :title="'桐生に住む魅力'"
        :descriptionSize="'lg'"
        :maxWidth="1040"
        :marginTop="112"
        :smMarginTop="80">
        <FlexList
          :spaceX="40"
          :spaceY="32"
          :count="4"
          :smCount="1"
          :align="'center'"
          :wrap="true">
          <li v-for="(item, i) in charms" :key="i">
            <ContentCard
              :imgBorderRadius="28"
              :img="item.media"
              :title="item.label"
              :content="item.content"
              :contentSidePadding="0"
              :fontSize="12">
            </ContentCard>
          </li>
        </FlexList>
        <Spacer :y="4"/>
        <FlexList :align="'center'">
          <li><basic-btn :tag="'a'" :link="'/contact/'">移住コーディネーターに問い合わせてみる</basic-btn></li>
        </FlexList>
      </SectionFrame>
    </SectionContainer>

    <SectionContainer id="topics">
      <SectionFrame
        :title="'お役立ちトピックス'"
        :description="'桐生市で「住む」ために役立つコンテンツをまとめ、随時更新しています。'"
        :descriptionSize="'lg'">
        <Bg :bgcolor="'gray'" :radius="28">
          <TopicList
            type="sumu"
            :limit="5" />
          <FlexList :align="'center'">
            <basic-btn
              :color="'gry'"
              :tag="'a'"
              :link="'/sumu/topics/'">もっと見る</basic-btn>
          </FlexList>
          <spacer :y="4"/>
        </Bg>
      </SectionFrame>
    </SectionContainer>

    <SectionContainer id="simulation">
      <SectionFrame
        :title="'桐生暮らしシミュレーション'"
        :description="'自分がこれから桐生市に移住する場合、どんな支援が対象になるのか、また自分の好みや条件だと、どんな桐生での暮らし方ができるのか、シミュレーションしてみましょう。'"
        :descriptionSize="'lg'">
        <SimulationBanners name="sumu"/>
      </SectionFrame>
    </SectionContainer>

    <SectionContainer id="find-house">
      <SectionFrame
        :title="'住まいを探す'"
        :description="'空き家・空き地バンクでは検索機能を充実しています。あなたの理想とする物件探しのお手伝いをさせていただきますので、お気軽に移住コーディネータにお問い合わせください。'"
        :descriptionSize="'lg'">
        <SimpleList
          :hasLine="false"
          :margin="32"
          v-if="card.cards?.length">
          <BgListItem
            v-for="item in adjustCards(card.cards, 'findHome')"
            :key="item"
            :bgcolor="'gray'">
              <ContentListFrame :item="linkAdjustedItem(item)" />
          </BgListItem>
        </SimpleList>
      </SectionFrame>
    </SectionContainer>

    <SectionContainer id="support">
      <SectionFrame
        :title="'住まいに関する支援制度'"
        :description="'住まいに関する支援メニューを紹介します。'"
        :descriptionSize="'lg'">
        <SimpleList
          :hasLine="false"
          :margin="24"
          v-if="card.cards?.length">
          <BgListItem
            v-for="item in adjustCards(card.cards, 'supports')"
            :key="item"
            :bgcolor="'gray'">
              <ContentListFrame :item="item" />
          </BgListItem>
        </SimpleList>
      </SectionFrame>
    </SectionContainer>

    <spacer :y="20" :smY="15"/>

    <Interview
      id="interview"
      :category="categoryId" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  Spacer,
  SectionFrame,
  BasicBtn,
  ContentListFrame,
  Bg,
  SectionContainer,
  SimpleList,
  FlexList,
  ContentCard,
  Interview,
  TopicList,
  BgListItem,
  SimulationBanners,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'support-living',
  components: {
    TopicList,
    BgListItem,
    Spacer,
    SectionFrame,
    BasicBtn,
    ContentListFrame,
    Bg,
    SectionContainer,
    SimpleList,
    FlexList,
    ContentCard,
    Interview,
    SimulationBanners,
  },
  mixins: [cf],
  data() {
    return {
      akiyaUrls: {
        local: 'http://localhost:4088/',
        develop: 'https://dev.kiryu-akiya.jp',
        production: 'https://kiryu-akiya.jp',
      },
      charms: [
        {
          label: '街？自然？選べるエリア',
          content: '自然豊かな山の麓で暮らす人もいれば、歴史ある街並みの中、駅近で便利に暮らす人もいたりと、自分のライフスタイル・ステージに合わせた居住エリア選びができます。',
          media: '/images/detail/living_charm_1.jpeg',
        },
        {
          label: '住み続けたい街関東ナンバー1',
          content: '移住者も出身者も桐生が好きで、まちを全力で楽しんでいる人が多い印象。こだわりの繊維雑貨や地元ブランド米、地産野菜で丁寧な暮らしができるまちです。',
          media: '/images/detail/living_charm_2.jpg',
        },
        {
          label: '実は災害が少ない地域',
          content: '桐生市のある群馬県は、震度4以上の地震の頻度が関東甲信越地域で最も低いレベルです。また、年間の日照時間は全国第４位（気象庁「過去の気象データ」2019）で、晴れの日が多く、台風などの風水害被害も少ないので暮らしやすい地域です。',
          media: '/images/detail/living_charm_3.jpg',
        },
      ],
      card: {
        loading: true,
        notFound: false,
        cards: [],
      },
    };
  },
  created() {
    this.getCards();
  },
  computed: {
    ...mapState(['helper']),
    type() {
      return this.$route.meta.name || null;
    },
    categoryId() {
      return this.helper?.categories?.find((d) => d.name === this.type)?.id || null;
    },
  },
  methods: {
    linkAdjustedItem(item) {
      const target = item;
      if (target.buttons && target.buttons.length) {
        target.buttons.forEach((btn) => {
          if (btn.link === 'akiya') btn.link = this.akiyaUrls[this.helper.env.name || 'production'];
        });
      }
      return target;
    },

    getCards() {
      const params = {
        flags: [1],
        type: this.type,
        orderBy: 'order',
        order: 'asc',
      };

      this.axios({
        method: 'GET',
        url: '/v1/card/get/list',
        params,
      })
        .then((response) => {
          this.card.cards = response.data.cards.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
          this.card.notFound = true;
        })
        .finally(() => {
          this.card.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" module="s">
</style>
