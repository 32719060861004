<template>
  <div :class="s.base" v-if="pageData">
    <div :class="[s.wrap, s[pageData.headColor], pageData.showMenu ? s.hasMenu : '']">
      <div :class="s.text">
        <p
          v-if="pageData.title"
          :class="s.title"
          class="n2br"
          v-html="pageData.title"/>
        <p
          v-if="pageData.description"
          :class="s.description"
          v-html="pageData.description" />
        <MenuBar
          v-if="pageData.showMenu"
          class="sp"
          :class="[s.menu, s.sp]"/>
        <spacer v-if="!pageData.showMenu" :y="6"/>
      </div>
      <img src="/images/layout/detail_arc.svg" alt="arc" :class="s.arc">
    </div>
    <MenuBar
      v-if="pageData.showMenu"
      :class="[s.menu, s.pc]"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  MenuBar,
  Spacer,
} from '../parts';

export default {
  name: 'detail-head',
  components: {
    Spacer,
    MenuBar,
  },
  computed: {
    ...mapState(['page']),
    pageData() {
      if (!this.$route.meta || !this.$route.meta.showDetailHead) return null;
      if (this.$route.meta.name === 'topics-slug' && !this.page.title) return null;
      const obj = this.$route.meta;
      if (this.page.title) obj.title = this.page.title;
      if (this.page.description) obj.description = this.page.description;
      if (this.page.headColor) obj.headColor = this.page.headColor;
      return obj;
    },
  },
};
</script>

<style lang="scss" module="s">
.base {
  position: relative;
}
.wrap {
  position: relative;
  padding: var(--header-height) 20px 100px;
  overflow: hidden;
  mix-blend-mode: multiply;
  transition: all .3s;

  &.hasMenu {
    padding-bottom: 180px;
  }

  &.yellow { background: var(--bg-yellow); }
  &.purple { background: var(--bg-purple); }
  &.green { background: var(--bg-green); }
  &.red { background: var(--bg-red); }
  &.gray { background: var(--bg-gray); }
  &.blue { background: var(--bg-blue); }
  &.yellowgreen { background: var(--bg-yellowgreen); }
  &.pink { background: var(--bg-pink); }
}
.text {
  position: relative;
  z-index: 1;
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}
.title {
  font-size: 48px;
  font-weight: 700;
  opacity: .8;
}
.description {
  margin-top: 15px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.menu {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -30px);
  &.sp {
    display: none;
  }
}
.arc {
  position: absolute;
  width: 120%;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 65%);
}

@include smView {
  .wrap {
    padding-top: calc(var(--header-height) + 32px);
    padding-bottom: 0;
    overflow: visible;

    &.hasMenu {
      padding-bottom: 0;
    }
  }

  .title {
    font-size: 40px;
  }

  .description {
    font-size: 14px;
  }

  .menu {
    position: relative;
    margin-top: 32px;
    transform: translate(-50%, 0);
    &.sp {
      display: flex;
    }
    &.pc {
      display: none;
    }
  }
}
</style>
