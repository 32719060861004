<template>
  <div
    :class="[
      s.btn_wrap,
      imageType === 'fit' ? s.fit : '',
    ]"
    :style="bgImage && imageType === 'fit' ? `background-image: url(${bgImage});` : ''">
    <div v-if="bgImage && imageType === 'prefix'"
      :class="[s.img, s[`img_${imageType}`]]"
      :style="`background-image: url(${bgImage});`"></div>
    <div :class="[
      s.btn,
      s[`align_${align}`],
      bgImage && imageType === 'fit' ? s.fit : '',
      bgImage ? s.hasImg : '',
    ]">
      <slot />
      <i
        v-if="hasIcon"
        class="fa-solid fa-circle-check"
        :class="s.icon"/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hasIcon: {
      type: Boolean,
      default: true,
    },
    align: {
      type: String,
      default: 'between',
    },
    imageType: {
      type: String,
      default: 'prefix', // prefix, fit
    },
    bgImage: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" module="s">
.btn_wrap {
  border: 2px solid var(--label-primary);
  border-radius: 28px;
  cursor: pointer;
  display: flex;
  overflow: hidden;
  &.fit {
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    transition: all .3s;
    &:hover {
      background-size: 110%;
    }
  }
}
.btn {
  position: relative;
  padding: 20px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  color: var(--label-primary);
  width: 100%;
  transition: all .3s;

  i {
    font-size: 24px;
    margin-left: 12px;
  }
  &.fit {
    color: #ffffff;
    justify-content: center !important;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, .9);
    background-position: center;
  }
  &.hasImg {
    &:hover {
      background-color: #ffffff;
      color: var(--label-primary);
      opacity: .3;
    }
    &.fit {
      color: #ffffff;
      background-color: transparent;
      opacity: 1;
    }
  }

  &.align_center {
    justify-content: center;
  }
  &:hover {
    background-color: var(--label-primary);
    color: #ffffff;
  }
  @include smView {
    &:hover {
      opacity: 1;
    }
  }
}
.icon {
  font-size: 24px;
  position: absolute;
  right: 20px;
}
.img {
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  &_prefix {
    padding-top: 100px;
    width: 40%;
    border-radius: 28px 0 0 28px;
  }
}
</style>
