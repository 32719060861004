<template>
  <div :class="s.container">
    <Spacer :y="4"/>
    <basic-btn
      :tag="'a'"
      :link="'/'">TOPページへ</basic-btn>
    <Spacer :y="4"/>
  </div>
</template>

<script>
import {
  Spacer,
  BasicBtn,
} from '@/components/parts';

export default {
  name: 'notFound',
  components: {
    Spacer,
    BasicBtn,
  },
};
</script>

<style lang="scss" module="s">
.container {
  display: flex;
  flex-flow: column;
  align-items: center;
}
</style>
