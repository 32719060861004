<template>
  <div
    :class="[s.wrap, activeType ? s.isActive : '']"
    @click.self="activeType = null">
    <div
      v-for="menu in menues"
      :key="menu.name"
      :class="[
        s.menu,
        s[menu.name],
        activeType === menu.name ? s.isActive : '',
        activeType !== menu.name && activeType !== null ? s.isHidden : '',
        s.pointer,
      ]"
      @click="activeType = menu.name">
      <div :class="s.label">
        <div>{{ menu.label }}</div>
        <div 
          :class="[
            s.btn,
            activeType === menu.name ? s.isActive : '',
          ]"
          @click.stop="activeType = menu.name === activeType ? null : menu.name"><span/><span/></div>
      </div>
      <div :class="[
        s.items,
        activeType === menu.name ? s.isActive : ''
      ]">
        <ul :class="s.lines">
          <li
            v-for="link in childrenMenu[menu.name]"
            :key="link.name"
            :class="s[menu.name]">
            <router-link :to="`/${menu.name}/#${link.name}`">{{ link.label }}</router-link>
          </li>
        </ul>
        <!-- <ul :class="[s.btns]">
          <li
            v-for="link in childrenMenu[menu.name]"
            :key="link.name"
            :class="s[menu.name]">
            <router-link :to="`/${menu.name}/#${link.name}`">{{ link.label }}</router-link>
          </li>
        </ul> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bottom-menu',
  data() {
    return {
      activeType: null,
      menues: [
        {
          name: 'kaigyou',
          label: '店舗開業・起業のすすめ',
        },
        {
          name: 'shien',
          label: '移住支援・サポート体制',
        },
      ],
      childrenMenu: {
        kaigyou: [
          {
            name: 'charm',
            label: '魅力',
          },
          {
            name: 'topics',
            label: 'お役立ちトピックス',
          },
          {
            name: 'empty-shop',
            label: '空き店舗紹介',
          },
          {
            name: 'organization',
            label: '支援機関',
          },
          {
            name: 'shien',
            label: '支援・助成制度',
          },
          {
            name: 'interview',
            label: '移住者インタビュー',
          },
        ],
        shien: [
          {
            name: 'step',
            label: '移住ステップ',
          },
          {
            name: 'window',
            label: '支援窓口',
          },
          {
            name: 'shien',
            label: '支援・助成',
          },
          {
            name: 'simulation',
            label: 'シミュレーション',
          },
          {
            name: 'interview',
            label: '移住者インタビュー',
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  transition: all .3s;
  &.isActive {
    width: 100%;
    height: 100%;
    background: rgba(#A0A0A0, $alpha: .5);
  }
}
.menu {
  transition: all .3s;
  position: absolute;
  font-size: 18px;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: auto;
  font-weight: 700;
  color: var(--label-primary);
  &.isHidden {
    bottom: -70px;
  }
  &.kaigyou {
    padding: 20px 23px 20px 37px;
    width: 300px;
    z-index: 2;
    background-color: #E5D842;
    border-radius: 0 34px 0 0;


    &.isActive {
      border-radius: 0 112px 0 0;
      padding: 31px 56px 0px 44px;
      width: 100vw;
      .label {
        font-size: 24px;
        font-weight: normal;
      }
    }
  }
  &.shien {
    z-index: 1;
    background-color: #84CDC1;
    margin-left: 166px;
    padding: 20px 18px 20px calc(134px + 29px);
    width: 432px;
    border-radius: 34px 34px 0 0;

    &.isActive {
      border-radius: 34px 112px 0 0;
      padding: 31px 56px 0px calc(44px + 34px);
      margin-left: -34px;
      width: calc(100vw + 34px);
      .label {
        font-size: 24px;
        font-weight: normal;
      }
    }
  }

  .label {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.btn {
  margin-left: 16px;
  width: 28px;
  height: 28px;
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  span {
    position: absolute;
    height: 2px;
    width: 10px;
    transform-origin: 0px;
    left: 50%;
    top: 35%;
    border-radius: 2px;
    background-color: #000000;
    &:first-child {
      transform: rotate(40deg) translate(-1px);
    }
    &:last-child {
      transform: rotate(-40deg) translate(calc(-100% + 1px), 0);
    }
  }

  &.isActive {
    width: 49px;
    height: 49px;
    transform-origin: center;
    span {
      width: 20px;
      top: 50%;
      left: 50%;
      &:first-child {
        transform: translate(-7px, -7px) rotate(45deg);
      }
      &:last-child {
        transform: translate(-7px, 7px) rotate(-45deg);
      }
    }
  }
}

.items {
  transition: all .3s;
  display: flex;
  align-items: center;
  height: 0;
  overflow: hidden;
  font-weight: 700;
  opacity: 0;
  padding:  0 0 0 0;

  .lines {
    display: flex;
    > li {
      a {
        color: var(--label-primary);
      }
      &:not(:first-child) {
        margin-left: 18px;
        padding-left: 22px;
        position: relative;
        &::before {
          content: '/';
          color: var(--border-divider-gray);
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
  .btns {
    display: flex;
    margin-left: 48px;
    font-size: 16px;
    > li {
      padding: 11px 24px;
      background-color: #ffffff;
      border-radius: 40px;
      &:not(:first-child) {
        margin-left: 14px;
      }
      a {
        color: var(--label-primary);
      }
    }
  }

  &.isActive {
    opacity: 1;
    overflow-x: scroll;
    overflow-y: hidden;
    height: 48px;
    padding: 57px 0 68px;
  }
}

.pointer {
  cursor: pointer;
}

@include smView {
  .wrap {
    width: 100%;
  }
  .menu {
    &.kaigyou, &.shien {
      width: 50%;
      border-radius: 0;
      padding: 20px;
      font-size: 14px;
      font-weight: 700;
      .label {
        justify-content: center;
        text-align: center;
      }
      .btn {
        display: none;
      }
      &.isActive {
        padding: 64px 20px 0;
        .label {
          margin-right: 0;
          width: 100%;
          justify-content: flex-start;
          text-align: start;
          position: relative;
          opacity: 1;
        }
        .btn {
          top: -24px;
          right: 20px;
          position: absolute;
          display: block;
          flex-shrink: 0;
        }
      }
    }
    &.kaigyou {
    }
    &.shien {
      transform: translateX(100%);
      margin-left: 0;
      &.isActive {
        transform: translateX(0);
        padding-left: 54px;
      }
    }
    .label {
      opacity: .8;
    }

    .items {
      display: block;
      &.isActive {
        padding: 50px 0 0;
        height: 200px;
      }
      .lines {
        flex-wrap: wrap;
        > li {
          &:not(:first-child) {
            margin-left: 0;
          }
          margin-right: 16px;
          margin-bottom: 16px;
        }
      }
      .btns {
        flex-wrap: wrap;
        margin-left: 0;
        > li {
          &:not(:first-child) {
            margin-left: 0;
          }
          margin-right: 16px;
          margin-bottom: 16px;
        }
      }
    }
  }
}
</style>
