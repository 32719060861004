<template>
  <div :class="[
    s.wrap,
    isActive ? s.active : '',
    s[bgcolor],
    s[size],
    s[type],
  ]">
    <span v-if="type === 'hash'">＃ </span>
    <span v-else-if="type === 'check'" :class="[s.icon, isActive ? s.active : '']"><i class="fa-regular fa-circle-check me-2"/></span>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'tag-button',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    bgcolor: {
      type: String,
      default: 'transparent',
      validator: (value) => ['transparent', 'gradation', 'none'].includes(value),
    },
    size: {
      type: String,
      default: 'lg',
      validator: (value) => ['lg', 'sm'].includes(value),
    },
    type: {
      type: String,
      default: 'default',
      validator: (value) => ['hash', 'check', 'category', 'default'].includes(value),
    },
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  display: inline-block;
  padding: 9px 20px;
  border-radius: 14px;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
  &.transparent {
    border: 1px solid rgba(0, 0, 0, 0.2);
    &.active,
    &:hover {
      border: 1px solid var(--label-primary);
      font-weight: 700;
    }
  }
  &.gradation {
    color: #ffffff;
    font-weight: 700;
    background: linear-gradient(134.56deg, #D289F7 0%, #69D1D1 102.35%);
  }

  &.sm {
    padding: 5px 10px;
    font-size: 10px;
  }

  &.category {
    color: var(--label-secondary);
    border-radius: 48px;
    background: none;
    background-color: var(--surface-point-gray);
    border: 1px solid var(--surface-point-gray);
    font-weight: 700;
  }
}

.icon {
  color: var(--label-disabled);
  font-size: 16px;
  &.active {
    color: var(--label-primary);
  }
}
</style>
