<template>
  <div>
    <SectionContainer id="simulation">
      <SectionFrame
        :marginTop="50"
        :smMarginTop="50">
        <SimulationBanners name="top"/>
      </SectionFrame>
    </SectionContainer>
    <spacer :y="20"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  SectionContainer,
  SectionFrame,
  Spacer,
  SimulationBanners,
} from '@/components/parts';

export default {
  name: 'interview',
  components: {
    SectionContainer,
    SectionFrame,
    Spacer,
    SimulationBanners,
  },
  data() {
    return {
      isSpView: false,
    };
  },
  created() {
    this.checkIsSpView();
  },
  computed: {
    ...mapState(['helper']),
  },
  methods: {
    checkIsSpView() {
      this.isSpView = window.innerWidth <= 768;
    },
  },
};
</script>

<style lang="scss" module="s">
</style>
