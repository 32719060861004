<template>
  <div
    class="wrapper"
    :class="[
      showHeaderFromTop ? s.bg : '',
      flag.showChangePageLayer ? 'hide-scrollbar' : '',
      s.wrapper,
    ]">
    <div :id="showHeaderFromTop ? 'show-trigger': ''"/> <!-- header表示のためのフック -->
    <GlobalHeader />
    <DrawerMenu />
    <DetailHead id="menu-trigger"/>
    <div class="contents" :id="showHeaderFromTop && !isTransparentHeader ? 'bg-trigger' : ''">
      <router-view />
    </div>
    <!-- <BottomMenu /> -->
    <GlobalFooter />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  GlobalHeader,
  GlobalFooter,
  DrawerMenu,
  DetailHead,
  // BottomMenu,
} from '@/components/layouts';
import axios from '@/plugins/axios';

export default {
  name: 'App',
  components: {
    GlobalHeader,
    GlobalFooter,
    DrawerMenu,
    DetailHead,
    // BottomMenu,
  },
  data() {
    return {
      flag: {
        showChangePageLayer: false,
      },
      timeoutIds: {
        resize: null,
        scroll: null,
      },
    };
  },
  created() {
    // ログインチェック
    this.$store.dispatch('user/login/check');
    // backend共通マスターデータ取得
    this.$store.dispatch('helper/getMaster');
    // categories取得
    this.$store.dispatch('helper/getCategories');
  },
  mounted() {
    window.addEventListener('scroll', this.checkScroll);
    window.addEventListener('resize', this.checkSize);
    this.$store.dispatch('layout/setDisplaySize', window.innerWidth);
    this.scrolled();
  },
  unmounted() {
    window.removeEventListener('scroll', this.checkScroll);
    window.removeEventListener('resize', this.checkSize);
  },
  computed: {
    ...mapState(['layout']),
    showHeaderFromTop() {
      return this.$route.meta?.showDetailHead || this.$route.meta?.showHeaderFromTop;
    },
    isTransparentHeader() {
      return this.$route?.meta?.isTransparentHeader;
    },
  },
  watch: {
    $route(to, from) {
      // storeのpage情報reset
      this.$store.dispatch('page/resetPageData');
      // メタ情報の仕込み
      this.setMetas(to);

      // トップまでスクロールしてしまうのをパッと切り替わるようにする
      if (to.path !== from.path) {
        this.flag.showChangePageLayer = true;
        setTimeout(() => {
          this.flag.showChangePageLayer = false;
          if (to.hash) {
            const id = to.hash.replace('#', '');
            this.$nextTick(() => {
              const elem = document.getElementById(id);
              if (elem) elem.scrollIntoView();
            });
          }
        }, 10);
      }

      // レイアウトに関する値をstoreにセット
      this.$nextTick(() => {
        this.scrolled();
      });
    },
  },
  methods: {
    setMetas: async (route) => {
      const ijuFrontBaseUrl = 'https://kiryu-iju.jp';
      const routeMeta = route.meta;
      // 個別設定がない場合のデフォルト設定
      const base = {
        title: 'むすびすむ桐生',
        description: '暮らす人、訪れる人、商う人、働く人。まちなかと自然。織都1300年の伝統と新しい風、感性豊かな糸と糸が、柔らかに交差し、今日もまた、どこかで編み出されていく。ゆるやかに、つながり、ほどよく、むすばれる。むすびすむ桐生へ',
        img: 'https://kiryu-iju.jp/images/ogp.png',
        type: route.name === 'Home' ? 'website' : 'article',
        og_url: 'https://kiryu-iju.jp/',
        og_image: 'https://kiryu-iju.jp/images/ogp.png',
        og_site_name: 'むすびすむ桐生',
      };
      const kaigyouDescription = '桐生市には地方・田舎には珍しくチェーン店が少ないと言われており、ここにしかない個性豊かな店舗がたくさんあります。店舗開業や起業、工房開設などに挑戦したい方に向けた各種支援制度や相談機関の情報を紹介します。';

      base.title = routeMeta?.title && routeMeta?.title !== 'top' ? `${routeMeta.title} | ${base.title}` : base.title;
      base.description = routeMeta?.description ? routeMeta.description : base.description;
      if (route.name.includes('kaigyou')) {
        base.description = kaigyouDescription;
      }

      if (route.name.includes('Detail')) {
        // slug特定
        const slug = route.params.slug;
        await axios({
          method: 'GET',
          baseURL: process.env.VUE_APP_APIBASE,
          url: '/v1/helper/get/master',
        })
          .then((response) => {
            // thenの後だとデータが扱えなかったためthen内で処理
            const res = response.data.master;
            if (route.path.includes('interview')) {
              const interviews = res.default.interview.interviews;
              const target = interviews.find((item) => item.open_id === route.params.open_id);
              if (target) {
                if (target.name) base.title = `${target.name} | ${base.title}`;
                if (target.content) base.description = target.content;
                if (target.media.url) base.img = `${ijuFrontBaseUrl}${target.media.url}`;
              }
            } else if (route.path.includes('topics')) {
              const topics = res.default.topics.items;
              const target = topics.find((item) => item.slug === slug);
              if (target) {
                if (target.title) base.title = `${target.title} | ${base.title}`;
                if (target.summary) base.description = target.summary;
                if (target.media) base.img = `${ijuFrontBaseUrl}${target.media}`;
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }

      // titleからタグを削除
      base.title = base.title.replace(/<br[^>]*>/g, '');
      // descriptionからタグを削除
      base.description = base.description.replace(/<br[^>]*>/g, '');
      base.description = base.description.replace(/<p[^>]*>/g, '');
      base.description = base.description.replace(/<\/p[^>]*>/g, '');

      // meta sets
      document.title = base.title;
      document.querySelector("meta[name='description']").setAttribute('content', base.description);
      document.querySelector("meta[property='og:title']").setAttribute('content', base.title);
      document.querySelector("meta[property='og:description']").setAttribute('content', base.description);
      document.querySelector("meta[property='og:type']").setAttribute('content', base.type);
      document.querySelector("meta[property='og:image']").setAttribute('content', base.og_image);
      document.querySelector("meta[property='og:url']").setAttribute('content', base.og_url);
      document.querySelector("meta[property='og:site_name']").setAttribute('content', base.og_site_name);
    },
    checkSize() {
      // スクロールを停止して500ms後に終了とする
      if (this.timeoutIds.resize) return;
      this.timeoutIds.resize = setTimeout(() => {
        this.timeoutIds.resize = 0;
        if (window) this.$store.dispatch('layout/setDisplaySize', window.innerWidth);
      }, 500);
    },
    checkScroll() {
      // スクロールを停止して500ms後に終了とする
      if (this.timeoutIds.scroll) return;
      this.timeoutIds.scroll = setTimeout(() => {
        this.timeoutIds.scroll = 0;
        this.scrolled();
      }, 500);
    },
    scrolled() {
      const showTrigger = document.getElementById('show-trigger');
      const bgTrigger = document.getElementById('bg-trigger');
      const menuTrigger = document.getElementById('menu-trigger');

      const showTriggerTop = showTrigger ? showTrigger.getBoundingClientRect().top : null;
      const bgTriggerTop = bgTrigger ? bgTrigger.getBoundingClientRect().top : null;
      const menuTriggerTop = menuTrigger ? menuTrigger.getBoundingClientRect().top : null;

      const isScrolledShow = showTriggerTop !== null ? showTriggerTop < 100 : false;
      const isScrolledColor = bgTriggerTop !== null ? bgTriggerTop < 100 : false;
      const isScrolledMenu = menuTriggerTop !== null ? menuTriggerTop <= 0 : false;

      const headerStatus = {
        color: isScrolledColor ? 'semi-transparent-white' : 'transparent',
        isShow: Boolean(isScrolledShow),
      };
      const menuStatus = {
        isShow: Boolean(isScrolledMenu),
      };

      this.$store.dispatch('layout/setHeaderStatus', headerStatus);
      this.$store.dispatch('layout/setMenuStatus', menuStatus);
    },
  },
};
</script>

<style lang="scss">
/**
 * ここでimportしているscssは
 * 全てのページに適用されます
 */
@import './assets/scss/_variables.scss';
@import './assets/scss/_keyframes.scss';
@import './assets/scss/_mq.scss';
@import './assets/scss/_fonts.scss';
@import './assets/scss/modal.scss';
@import './assets/scss/style.scss';
</style>

<style lang="scss" module="s">
.bg {
  background-image: url('/images/bg_semi_transparent_0.5.png');
}
.wrapper {
  width: 100%;
  overflow: hidden;
}
</style>
