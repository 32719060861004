<template>
  <div :class="s.wrap">
    <div :class="s.img">
      <CircleImg :url="cordinator.media"/>
    </div>
    <div>
      <div :class="s.base">
        <p :class="s.name">{{ cordinator.name }}</p>
        <p :class="s.kana">{{ cordinator.kana }}</p>
        <div
          v-if="introduction"
          :class="s.introduction"
          v-html="introduction"/>
      </div>
      <ul :class="s.list">
        <li v-for="item in items" :key="item.name">
          <div :class="s.label">{{ item.label }}</div>
          <div :class="s.content" v-html="cordinator[item.name]" class="n2br"/>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CircleImg from './CircleImg.vue';

export default {
  name: 'cordinator',
  components: {
    CircleImg,
  },
  props: {
    cordinator: {
      type: Object,
    },
  },
  data() {
    return {
      introductionItems: [
        {
          name: 'from',
          after: '出身',
        },
        {
          name: 'organization',
          before: '職業',
        },
        {
          name: 'area',
          before: '詳しいエリア',
        },
        {
          name: 'relation',
          before: '繋がりのある分野',
        },
        {
          name: 'hobby',
          before: '趣味',
        },
      ],
      items: [
        {
          name: 'work',
          label: '普段の仕事',
        },
        {
          name: 'speciality',
          label: '特技・強み',
        },
        {
          name: 'cue',
          label: '桐生と深く関わるきっかけ',
        },
        {
          name: 'favorite',
          label: '桐生の一番のお気に入り',
        },
        {
          name: 'comment',
          label: '移住検討中の方に一言',
        },
      ],
    };
  },
  computed: {
    introduction() {
      let str = '';
      this.introductionItems.forEach((item) => {
        let add = '';
        if (item.name === 'organization') {
          add = this.cordinator.organizations?.[0]?.label || '';
        } else {
          add = this.cordinator[item.name];
        }

        const before = item.before ? `${item.before}：` : '';
        const after = item.after || '';

        if (add) {
          if (str) str += ' / ';
          str += `${before}${add}${after}`;
        }
      });
      return str;
    },
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  display: flex;
  flex-flow: column;
  align-items: center;
}
.img {
  width: 66%;
  margin-bottom: 12px;
}
.base {
  text-align: center;
}
.name {
  font-weight: 700;
  margin-bottom: 12px;
  font-size: 14px;
  margin-top: 12px;
}
.kana {
  font-size: 12px;
}
.introduction {
  margin-top: 12px;
  font-size: 12px;
  color: var(--label-primary);
  text-align: left;
}
.list {
  font-size: 12px;
  margin-top: 12px;
  > li {
    &:not(:first-child) {
      margin-top: 12px;
    }
  }
  .label {
    font-weight: 700;
  }
}
</style>
