<template>
  <div>
    <div id="tag-list_wrap" :style="tagStyle" :class="s.tag_list_wrap">
      <ul :class="s.tag_list" id='tag-list'>
        <slot />
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tag-list',
  data() {
    return {
      align: 'start',
      overflowX: 'scroll',
    };
  },
  mounted() {
    this.checkTagListWidth();
  },
  computed: {
    tagStyle() {
      return {
        '--align': this.align,
        '--overflowX': this.overflowX,
      };
    },
  },
  methods: {
    checkTagListWidth() {
      this.$nextTick(() => {
        const wrapW = document.getElementById('tag-list_wrap').clientWidth;
        const listW = document.getElementById('tag-list').scrollWidth;
        if (wrapW >= listW) {
          this.align = 'center';
          this.overflowX = 'hidden';
        }
      });
    },
  },
};
</script>

<style lang="scss" module="s">
.tag_list {
  margin-left: -8px;
  > li {
    display: inline-block;
    justify-content: var(--align);
    margin: 0 8px 6px 8px;
  }
}
</style>
