// import router from '@/router';
/**
 * View情報管理
*/

const actions = {
  // TODO: 定数化
  setDisplaySize({ commit }, windowWidth) {
    if (windowWidth >= 1024) {
      commit('setDisplaySize', 'lg');
    } else if (windowWidth >= 767) {
      commit('setDisplaySize', 'md');
    } else if (windowWidth >= 375) {
      commit('setDisplaySize', 'sm');
    } else {
      commit('setDisplaySize', 'xs');
    }
  },
  
  setHeaderStatus({ commit }, args = { isShow: true, isShowTitle: false }) {
    const params = {
      isShow: args.isShow,
      isShowTitle: args.isShowTitle,
    };

    // color
    const color = ['solid-white', 'semi-transparent-white', 'transparent'];
    if (args.color) params.color = color.includes(args.color) ? args.color : 'transparent';
    
    commit('setHeaderState', params);
  },

  setMenuStatus({ commit }, args = { isShow: false }) {
    commit('setMenuState', args);
  },
};

const mutations = {
  setDisplaySize(state, size) {
    state.displaySize = size;
    state.sizeChecked = true;
  },
  setHeaderState(state, params) {
    state.header = params;
  },
  setMenuState(state, params) {
    state.menu = params;
  },
};

const state = {
  sizeChecked: false,
  displaySize: 'lg',
  header: {
    isShow: true,
    isShowTitle: false,
    color: 'transparent',
  },
  menu: {
    isShow: false,
  },
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
};
