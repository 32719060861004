<template>
  <ul :class="[
      s.list,
      s[layout],
      s[align],
      wrap ? s.wrap : '',
      count ? s.count : '',
    ]"
    :style="style"
    ref="frame">
    <slot
      id="slot"
      />
  </ul>
</template>

<script>
export default {
  name: 'list',
  props: {
    layout: {
      type: String,
      default: 'horizontal',
      validator: (value) => ['horizontal', 'vertical'].includes(value),
    },
    align: {
      type: String,
      default: 'start',
      validator: (value) => ['start', 'center'].includes(value),
    },
    smAlign: {
      type: String,
      validator: (value) => ['start', 'center'].includes(value),
    },
    spaceX: {
      type: Number,
      default: 0,
    },
    smSpaceX: {
      type: Number,
    },
    spaceY: {
      type: Number,
      default: 0,
    },
    smSpaceY: {
      type: Number,
    },
    wrap: {
      type: Boolean,
      default: false,
    },
    count: {
      type: Number,
      default: 0,
    },
    smCount: {
      type: Number,
    },
    overflowX: {
      type: String,
      default: 'hidden',
      validator: (value) => ['hidden', 'scroll'].includes(value),
    },
    overflowY: {
      type: String,
      default: 'hidden',
      validator: (value) => ['hidden', 'scroll'].includes(value),
    },
  },
  computed: {
    style() {
      return {
        '--align': this.align,
        '--align-sm': this.calcSmAlign,
        '--margin-x': `${this.spaceX}px`,
        '--margin-x-sm': `${this.calcSmSpaceX}px`,
        '--margin-y': `${this.spaceY}px`,
        '--margin-y-sm': `${this.calcSmSpaceY}px`,
        '--margin-minus-x': `-${this.spaceX}px`,
        '--margin-minus-x-sm': `-${this.calcSmSpaceX}px`,
        '--margin-minus-y': `-${this.spaceY}px`,
        '--margin-minus-y-sm': `-${this.calcSmSpaceY}px`,
        '--list-item-width': `calc(100% / ${this.count})`,
        '--list-item-width-sm': `calc(100% / ${this.calcSmCount})`,
        '--overflow-x': this.checkOverflowX,
        '--overflow-y': this.overflowY,
      };
    },
    calcSmAlign() {
      if (this.smAlign) return this.smAlign;
      return this.align;
    },
    calcSmCount() {
      if (this.smCount) return this.smCount;
      return this.count;
    },
    calcSmSpaceX() {
      if (this.smSpaceX) return this.smSpaceX;
      return this.spaceX;
    },
    calcSmSpaceY() {
      if (this.smSpaceY) return this.smSpaceY;
      return this.spaceY;
    },
    minusSpaceX() {
      return this.spaceX * -1;
    },
    minusSpaceY() {
      return this.spaceY * -1;
    },
  },
  methods: {
    checkOverflowX() {
      // 横方向のオーバーフローが発生するかどうかを判定
      this.$nextTick(() => {
        const frameWidth = this.$refs.frame.$el.clientWidth;
        const slotWidth = document.querySelector('#slot').scrollWidth;
        return this.overflowX === 'scroll' && slotWidth > frameWidth ? 'scroll' : 'hidden';
      });
    },
  },
};
</script>

<style lang="scss" module="s">
.list {
  overflow-x: var(--overflow-x);
  overflow-y: var(--overflow-y);
  display: flex;
  margin-right: var(--margin-minus-x);
  margin-bottom: var(--margin-minus-y);
  @include smView {
    margin-right: var(--margin-minus-x-sm);
    margin-bottom: var(--margin-minus-y-sm);
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.horizontal {
    &.center {
      justify-content: var(--align);
      @include smView {
        justify-content: var(--align-sm);
      }
    }
  }

  &.vertical {
    flex-flow: column;
    &.center {
      align-items: var(--align);
      @include smView {
        align-items: var(--align-sm);
      }
    }
  }

  > li {
    width: fit-content;
    padding-right: var(--margin-x);
    padding-bottom: var(--margin-y);
    @include smView {
      padding-right: var(--margin-x-sm);
      padding-bottom: var(--margin-y-sm);
    }
  }

  > a {
    width: fit-content;
    padding-right: var(--margin-x);
    padding-bottom: var(--margin-y);
    @include smView {
      padding-right: var(--margin-x-sm);
      padding-bottom: var(--margin-y-sm);
    }
  }

  &.count {
    > li {
      width: var(--list-item-width);
      @include smView {
        width: var(--list-item-width-sm);
      }
    }
  }
}
</style>
