/**
 * axiosの共通設定
 */
import axios from 'axios';

const baseURL = process.env.VUE_APP_APIBASE;
const Authorization = process.env.VUE_APP_TOKEN;

export default axios.create({
  headers: {
    Authorization,
  },
  baseURL,
  responseType: 'json',
});
