<template>
  <ul :class="[
    s.list,
    hasLine ? s.line : '',
    hasBottomLine ? s.bottom_line : '',
  ]"
  :style="style">
    <slot />
  </ul>
</template>

<script>
export default {
  name: 'simple-list',
  props: {
    hasLine: {
      type: Boolean,
      default: true,
    },
    hasBottomLine: {
      type: Boolean,
      default: false,
    },
    margin: {
      type: Number,
      default: 8,
    },
    maxWidth: {
      type: Number,
      default: 800,
    },
  },
  computed: {
    style() {
      return {
        '--max-width': `${this.maxWidth}px`,
        '--margin': `${this.margin}px`,
      };
    },
  },
};
</script>

<style lang="scss" module="s">
.list {
  margin: 0 auto;
  max-width: var(--max-width);
  > li {
    &:not(:first-child) {
      margin-top: var(--margin);
    }

  }
  &.line {
    > li {
      &:not(:last-child) {
        border-bottom: 1px solid var(--line-thin-secondary);
      }
    }
  }
  &.bottom_line {
    border-bottom: 1px solid var(--line-thin-secondary);
  }
}
</style>
