<template>
  <div class="signup">
    <div class="top" v-if="isTop">
      <form
        class="form_login form"
        autocomplete="off">
        <h2>新規会員登録</h2>

        <p
          v-if="!flg.exitst && !flg.regist">
            メールアドレスを入力し送信してください
        </p>

        <div>
          <div>
            <input
              id="mail"
              class=""
              type="email"
              name="mail"
              placeholder="メールアドレス"
              v-model.trim="$v.mail.$model"
              v-bind:disabled="flg.regist"
              v-bind:class="{ input_error: validationFlags.mail }"
              v-on:input="invalidsCheck('mail', $v.mail.$invalid)">
            <span class="slide-in"></span>
          </div>
          <p v-if="$v.mail.$dirty
              && $v.mail.email.$invalid">
              正しいメールアドレスの形式で入力してください
          </p>
          <p
            v-if="!flg.exitst && flg.regist">
            ご入力いただいたメールアドレスにメールを送信しました。<br>メールに記載されているURLからパスワードを登録してください。
          </p>
          <p
            v-if="flg.exists && !flg.regist">
            送信いただいたメールアドレスは登録済みです。<br>
            <a href="/login/" class="link">ログインページ</a>
            よりログインしてください。<br>パスワード設定がまだの場合は、メールアドレス登録時に送信されたメールに記載のURLよりパスワード設定を行ってください。
          </p>

          <div
            v-if="!flg.regist">
            <button
              @click="submit"
              v-bind:disabled="!submitFlag">
            送信</button>
          </div>
        </div>
      </form>

    </div>

    <!-- 下層テンプレート読み込み（要ルータchildren） -->
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import cf from '@/mixins/commonFunctions.js';


export default {
  name: 'Signup',
  mixins: [cf],
  components: {
  },
  data() {
    return {
      isTop: false,
      flg: {
        exists: false,
        regist: false,
        error: false,
      },
      validationFlags: {
        mail: false,
      },
      invalids: {
        mail: true,
      },
      // 有効期限切れでリダイレクトされた
      isExpired: false,
    };
  },
  computed: {
    ...mapState(['user']),
    submitFlag() {
      // 無効フラグが全てfalseならばtrueを返却
      return !this.invalids.mail;
    },
  },
  setup() {
    const mail = ref('');

    const rules = {
      // メールは必須・email形式
      mail: { required, email },
    };

    const $v = useVuelidate(rules, { mail });

    return { mail, $v };
  },
  created() {
    this.checkIsTop();
  },
  watch: {
    $route() {
      this.checkIsTop();
    },
  },
  methods: {
    checkIsTop() {
      const isTop = this.$route.name === 'Signup';
      this.isTop = isTop;
    },

    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },

    /** サブミット */
    submit(e) {
      e.preventDefault();

      // validateチェックは入力時に行われてる
      const data = {
        email: this.mail,
        flag: 10,
        role: 1,
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/create',
        data,
      })
        .then((response) => {
          const res = response.data;
          console.log(res);
          // 初期化
          this.flg.regist = false;
          this.flg.exists = false;
          // 初期登録
          if (!res.exists) this.flg.regist = true;
          // 登録済み
          else this.flg.exists = true;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">
</style>
