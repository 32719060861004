<template>
  <div>
    <SectionContainer id="about">
      <SectionFrame
        :marginTop="112"
        :smMarginTop="80">
        <SimpleList
          :hasLine="false"
          :margin="32">
          <li>
            <Bg
              :bgcolor="'gray'"
              :radius="28">
              <div :class="s.part">
                <div :class="s.title">群馬県桐生市は、こんなまち</div>
                <div
                  :class="[s.linespaced, s.sp_sm]"
                  class="n2br"
                  v-html="'織物のまちとして発展してきた桐生市は、ノコギリ屋根の織物工場や伝統的建造物が数多く残り、まちなかには国の「重要伝統的建造物群保存地区」に選定されている地区もあります。\n市内には渡良瀬川と桐生川が流れ、山々が連なり、水と緑に恵まれた自然豊かなまちでもあります。住む地域によって、便利なまちなか暮らしと里山風景の残る田舎暮らし、どちらも楽しめるのが桐生市の魅力です。'"/>
                <spacer :y="4.5" :smY="2.5"/>
                <img
                  src="/images/detail/about_kiryu_map.png"
                  alt=""
                  :class="[s.darken, s.center]">
              </div>
              <div :class="s.part">
                <img
                  src="/images/detail/about_detail_map.png"
                  alt=""
                  :class="[s.darken, s.center]">
                <spacer :y="4.5" :smY="2.5"/>
                <FlexList
                  :spaceX="16"
                  :spaceY="16"
                  :smSpaceY="24"
                  :wrap="true"
                  :count="3"
                  :smCount="1">
                  <li v-for="(area, i) in areas" :key="i">
                    <div :class="[s.area_title, area.ruby ? '' : s.noRuby]"><ruby>{{ area.label }}<rt>{{ area.ruby }}</rt></ruby>エリア</div>
                    <div class="n2br" :class="s.area_description" v-html="area.description"/>
                  </li>
                </FlexList>
              </div>
            </Bg>
          </li>
          <li>
            <Bg :bgcolor="'gray'" :radius="28">
              <div :class="s.part">
                <div :class="s.title">統計情報</div>
                <div :class="s.stat">
                  <div :class="s.people">
                    <div :class="s.stat_title">人口・世帯数</div>
                    <div :class="s.people_list_wrap">
                      <ul :class="s.people_list">
                        <li>総数・・102,655人</li>
                        <li>男・・・49,561人</li>
                        <li>女・・・53,094人</li>
                        <li>世帯・・49,322世帯</li>
                      </ul>
                    </div>
                    <div>（令和6年2月末現在）</div>
                  </div>
                  <div :class="s.climate">
                    <div :class="s.stat_title">自然気候</div>
                    <div :class="s.climate_content">
                      <div :class="s.climate_description">内陸で、まち全体に緑が多く、爽やかな風を感じられます。冬には上州名物の「空っ風」が吹きますが、降雪は年に数回です。</div>
                      <div :class="s.climate_list">
                        <ul>
                          <li>年平均気温：16.5°C</li>
                          <li>年間降水量：1101.0mm</li>
                          <li>年間日照時間：2572.5h</li>
                          <li>最低気温：-5.6°C</li>
                          <li>最高気温：39.7°C</li>
                        </ul>
                        <div>資料：気象庁（2023年）</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Bg>
          </li>
          <li>
            <Bg :bgcolor="'gray'" :radius="28">
              <div :class="s.part">
                <div :class="s.title">案内パンフレット</div>
                <div>
                  <FlexList
                    :spaceX="64"
                    :smSpaceX="20"
                    :spaceY="32"
                    :count="3"
                    :smCount="2"
                    :wrap="true">
                    <li v-for="poster in posters" :key="poster.name" :class="[s.poster]">
                      <div>
                        <img :src="poster.img" alt="">
                        <spacer :y="3"/>
                        <div :class="[s.bold, s.alignCenter]">{{ poster.title }}</div>
                        <spacer :y="3"/>
                      </div>
                      <div :class="s.btn_wrap">
                        <basic-btn
                          :type="'bdr'"
                          :size="'sm'"
                          :width="'fit-content'"
                          :tag="'a'"
                          :link="poster.link"
                          :openOtherTab="true">詳しく見る</basic-btn>
                      </div>
                    </li>
                  </FlexList>
                </div>
              </div>
            </Bg>
          </li>
          <li>
            <Bg :bgcolor="'gray'" :radius="28">
              <div :class="s.part">
                <div :class="s.title">交通アクセス</div>
                <div :class="s.traffic">
                  <div :class="s.map">
                    <img src="/images/detail/about_traffic_map.png" alt="" :class="s.darken">
                  </div>
                  <div :class="s.imgs">
                    <img src="/images/detail/about_train.png" alt="" :class="s.darken">
                    <spacer :y="3.5"/>
                    <img src="/images/detail/about_car.png" alt="" :class="s.darken">
                  </div>
                </div>
              </div>
            </Bg>
          </li>
        </SimpleList>
      </SectionFrame>
    </SectionContainer>

    <SectionContainer id="charm">
      <SectionFrame
        :title="'桐生暮らしの魅力'"
        :description="'いつからか、桐生市の魅力は「ゆるやかなつながり」だと言われるようになりました。それぞれがゆるやかでほどよい関係性でつながり、むすばれる。\nそんな桐生市で暮らす魅力を紹介します。'">
        <SimpleList
          :hasLine="false"
          :margin="32"
          v-if="card.cards?.length">
          <BgListItem
            v-for="item in adjustCards(card.cards, 'charms')"
            :key="item"
            :bgcolor="'gray'">
              <ContentListFrame :item="item" />
          </BgListItem>
        </SimpleList>
      </SectionFrame>
    </SectionContainer>

    <Spacer :y="7.5"/>

    <Spacer :y="12.5" id="instagram"/>
    <Bg :bgcolor="'gray'">
      <SectionContainer>
        <SectionFrame
          :title="'Instagramで見る桐生市'"
          :description="'Instagramを通して、住む人や訪れた人の目線で、よりリアルな桐生市の暮らしをお届けします。\n＃で発信される桐生を集めてみました。'"
          :marginTop="120"
          :smMarginTop="80"
          :maxWidth="1080">
          <spacer :y="4"/>
          <FlexList
            v-if="ig.hashtags.length"
            :spaceX="24"
            :align="'center'"
            :smAlign="'start'"
            :overflowX="'scroll'"
            :class="s.taglist">
            <li
              v-for="(row, index) in ig.hashtags"
              :key="row.ig_hashtag_id">
              <tag-btn
                :type="'hash'"
                :isActive="ig.tagIndex === index"
                @click="changeHashtag(index)">{{ row.ig_hashtag }}</tag-btn>
            </li>
          </FlexList>
          <spacer :y="4"/>

          <div :class="s.insta_label">人気の投稿</div>
          <Loader
            :flag="ig.loading"
            :mini="false">
            <div v-if="!ig.loading && ig.hashtags.length">
              <div v-if="ig.medias.length && !ig.notFound">
                <FlexList
                  :spaceX="24"
                  :smSpaceX="16"
                  :spaceY="24"
                  :smSpaceY="16"
                  :count="5"
                  :smCount="3"
                  :wrap="true">
                  <li
                    v-for="row in ig.medias"
                    :key="row.id">
                    <a
                      :href="row.permalink"
                      :class="s.insta_link"
                      target="_blank">
                      <div
                        :data-id="row.mediaId"
                        :class="s.insta_item"
                        :style="`background-image: url(${row.mediaUrl});`"/>
                      <p :class="s.insta_like_count"><i class="fal fa-heart"/>{{ row.likeCount }}</p>
                    </a>
                  </li>
                </FlexList>

                <div :class="s.center">
                  <spacer :y="10"/>
                  <basic-btn
                    @click="handleLoadMore"
                    >もっと見る</basic-btn>
                </div>
              </div>
              <div
                v-else>
                <p>ハッシュタグ「#{{ ig.hashtags[ig.tagIndex].ig_hashtag }}」の投稿は見つかりませんでした。</p>
              </div>
            </div>
          </Loader>

          <!-- 第２弾以降 -->
          <!-- <spacer :y="7"/>
          <div :class="s.insta_label">人気のアカウント</div>
          <FlexList :spaceX="40" :align="'center'" :count="6">
            <li v-for="n of 6" :key="n" :class="s.insta_account">
              <CircleImg :url="'/images/dammy_store.png'"/>
              <div :class="s.name">kiryu_city_club</div>
            </li>
          </FlexList> -->
        </SectionFrame>
      </SectionContainer>
      <Spacer :y="22.5" :smY="10"/>
    </Bg>


    <Spacer :y="12.5" :smY="5" id="movie"/>
    <SectionContainer>
      <SectionFrame
        :title="'動画で見る桐生市'"
        :description="'桐生市の暮らしや商いをイメージできる動画をむすびすむ桐生の移住コーディネーターがセレクトしました。\n今後も移住コーディネーターセレクトによる桐生市の魅力をお伝えする動画を公開していきます。'"
        :marginTop="120"
        :smMarginTop="80"
        :maxWidth="1080">

        <spacer :y="5" :smY="2" />
        <Movie
          :type="'youtube'"
          :id="'5XQJVvwL6z4'"
          :prefix="'移住コーディネーター 田中さんセレクト'"
          :title="'FUN KIRYU 桐生の紹介映像'" />

        <spacer :y="10" :smY="4" />
        <Movie
          :type="'instagram'"
          :id="'Cvt3JEEJCB7'"
          :prefix="'移住コーディネーター 川口さんセレクト'"
          :caption="'Movie by 武 耕平 (Kohei Take)'"
          :title="'桐生まつり写真展 桐生八木節まつり スライドショー'" />
      </SectionFrame>
    </SectionContainer>


    <!--
    <SectionContainer id="media">
      <SectionFrame
        :title="'メディアで見る桐生'"
        :description="'実は、桐生市にはアートな一面もあったりします。桐生に住む人が作る音楽、作品、この人もそうだったの？意外な発見があるかもしれません。'">
        <FlexList
          :spaceX="24"
          :align="'center'"
          :smAlign="'start'"
          :overflowX="'scroll'"
          :class="s.taglist">
          <li v-for="n of 4" :key="n">
            <tag-btn
              :isActive="n === 2"
              :type="'check'">WEB</tag-btn>
          </li>
        </FlexList>
        <spacer :y="4"/>
        <SimpleList
          :hasLine="false"
          :margin="32">
          <BgListItem
            v-for="item in medias"
            :key="item"
            :bgcolor="'gray'">
              <ContentListFrame :item="item" />
          </BgListItem>
        </SimpleList>
      </SectionFrame>
    </SectionContainer> -->
    <spacer :y="20" :smY="15"/>

    <Interview
      id="interview"
      :category="categoryId" />

  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  Spacer,
  SectionFrame,
  BasicBtn,
  TagBtn,
  ContentListFrame,
  Bg,
  SectionContainer,
  SimpleList,
  FlexList,
  Interview,
  Loader,
  BgListItem,
  Movie,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'support-living',
  components: {
    BgListItem,
    Spacer,
    SectionFrame,
    BasicBtn,
    TagBtn,
    ContentListFrame,
    Bg,
    SectionContainer,
    SimpleList,
    FlexList,
    Interview,
    Loader,
    Movie,
  },
  mixins: [cf],
  data() {
    return {
      areas: [
        {
          name: 'kurohone',
          label: '黒保根',
          ruby: 'くろほね',
          description: '最も北部に位置し、自然環境に恵まれた山間地域の涼しいエリアです。その気候を活かした野菜の生産、大自然の中でのリモートワーク、林業、キャンプ場での余暇活動など、本格的な田舎暮らしの中で、さまざまな暮らしにチャレンジできるエリアです。',
        },
        {
          name: 'kawauchi',
          label: '川内',
          ruby: 'かわうち',
          description: '渡良瀬川に注ぐ山田川などの小河川沿いに古くから織物産業が展開してきたエリアです。\n春の終わりには蛍が観察できるなど、自然に恵まれた暮らしのできるエリアです。',
        },
        {
          name: 'umeda',
          label: '梅田',
          ruby: 'うめだ',
          description: '清流「桐生川」の上流部の川沿いに立地するエリアです。\n梅田野菜やお茶の生産がされ、梅田湖を中心に自然やレジャーを満喫した暮らしのできるエリアです。',
        },
        {
          name: 'niisato',
          label: '新里',
          ruby: 'にいさと',
          description: '農業が盛んな地域で、近隣には商業施設が充実しています。県庁所在地の前橋市と隣接していることから通勤アクセスがよく、若い世代が多く暮らしています。北部にある別荘地では二拠点居住をする方もいるなど、ほどよい田舎暮らしのできるエリアです。',
        },
        {
          name: 'machinaka',
          label: 'まちなか周辺',
          description: '桐生市の中心エリアであり、行政施設、教育施設、医療施設、商業施設、文化施設などが集積し、重伝建地区をはじめとする文化財もある歴史文化の中心地です。\n都心をつなぐ東武線など鉄道3路線が乗り入れ、JR桐生駅は桐生エリアのバス交通の中心になっています。自然を眺めながら便利な暮らしのできるエリアです。',
        },
        {
          name: 'hishi',
          label: '菱',
          ruby: 'ひし',
          description: 'まちなかに近く、丘陵地には都市機能の整った2つの大きな住宅団地が開発され、見晴らしの良い、良好な住環境があるほか、自然環境の近くでゆったりと暮らすことのできるエリアです。',
        },
        {
          name: 'aioi',
          label: '相生',
          ruby: 'あいおい',
          description: 'もっとも多くの鉄道駅が立地し、国道122号も通ることから、アクセス性が高いエリアです。\n数多くの大型の商業施設や市民プール、市民体育館なども立地しているため、子育てに便利な暮らしのできるエリアです。',
        },
        {
          name: 'hirosawa',
          label: '広沢',
          ruby: 'ひろさわ',
          description: '東京方面からの玄関口となる東武新桐生駅が立地し、国道50号が通っていて、高速道路ICにも近いことから、通勤にも便利でアクセス性が高く、お出かけしやすいエリアです。',
        },
        {
          name: 'sakaino',
          label: '境野',
          ruby: 'さかいの',
          description: '栃木県足利市をつなぐ旧国道50号と桐生川沿いに市街地や商業施設が立地しています。大型の商業施設もあり、平地中心なので日常の買い物に便利なエリアです。',
        },
      ],
      posters: [
        {
          name: 'iju',
          img: '/images/kiryu_paper.jpg',
          title: '桐生市移住ガイドブック',
          link: 'https://www.city.kiryu.lg.jp/shisei/machi/iinekiryu/1022534.html',
        },
        {
          name: 'kurohone',
          img: '/images/kurohone.png',
          title: 'くろほねで、暮らす',
          link: 'https://www.city.kiryu.lg.jp/shisei/machi/iinekiryu/1022534.html',
        },
        {
          name: 'kankou',
          img: '/images/kiryu_poster.png',
          title: '桐生市観光総合ガイドブック',
          link: 'https://www.city.kiryu.lg.jp/kankou/annai/ichioshi/1010092.html',
        },
      ],
      medias: [
        {
          title: '（WEBメディア◉◉◉◉）「◉◉◉◉特集」',
          description: 'かつて、『西の京都、東の桐生』として織物、繊維の街として栄えた街です。\n現在は、クラフトマンの心意気が根付き、繊維だけでなく、手仕事として自分のこだわりが詰まった飲食店が増えています。',
          img: '/images/dammy_store.png',
          buttons: [
            {
              label: '詳細を読む',
              link: '/interview/slug/',
            },
          ],
        },
        {
          title: '（WEBメディア◉◉◉◉）「◉◉◉◉特集」',
          description: '待機児童0、その子にあった保育園や幼稚園を。子供の医療費無料、第三児以降の無料制度あり。\n週末は、入場料無料の動物園や遊園地に行ったり、桐生各地で開催されるイベントに参加して過ごす。大人も子供も楽しむことができるのも住み続けたい街(北関東1位)の秘密です。',
          img: '/images/dammy_store.png',
          buttons: [
            {
              label: '詳細を読む',
              link: '/interview/slug/',
            },
          ],
        },
        {
          title: '（WEBメディア◉◉◉◉）「◉◉◉◉特集」',
          description: '桐生市の周りには多くの自然があります。市の中心部からは車で15分、川や山へのアクセスも良好です。市内にはキャンプ場もあり、大自然を感じながら過ごすこともできます。\n自然の恵も豊富で、生産者さんのわかる新鮮なお野菜を道の駅や桐生の店舗で購入すること。できますができます。',
          img: '/images/dammy_store.png',
          buttons: [
            {
              label: '詳細を読む',
              link: '/interview/slug/',
            },
          ],
        },
      ],
      ig: {
        loading: true,
        notFound: false,
        hashtags: [],
        medias: [],
        tagIndex: 0,
        page: 1,
        limit: 20,
        lastPage: 1,
      },
      card: {
        loading: true,
        notFound: false,
        cards: [],
      },
    };
  },
  mounted() {
    this.getIgHashtags();
    this.getCards();
  },
  computed: {
    ...mapState(['helper']),
    type() {
      return this.$route.meta.name || null;
    },
    categoryId() {
      return this.helper?.categories?.find((d) => d.name === this.type)?.id || null;
    },
  },
  methods: {
    /**
     * 有効な登録ハッシュタグを取得
     */
    getIgHashtags() {
      this.axios({
        method: 'GET',
        url: '/v1/instagram/get/hashtags',
        params: {
          flags: [1],
          order: 'igHashtags.id',
          orderBy: 'desc',
        },
      })
        .then((response) => {
          this.ig.hashtags = response.data.list.data;
          // ハッシュタグを取得してから投稿を取得
          if (this.ig.hashtags.length) this.getIgMedias();
          else this.ig.loading = false;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
          this.ig.loading = false;
          this.ig.notFound = true;
        });
    },

    /**
     * ハッシュタグに連動した投稿を取得
     */
    getIgMedias(refresh = true) {
      if (refresh) {
        // ハッシュタグ切り替え時等に初期化
        this.ig.loading = true;
        this.ig.notFound = false;
        this.ig.medias = [];
        this.ig.page = 1;
        this.ig.lastPage = 1;
      }
      const currentTag = this.ig.hashtags[this.ig.tagIndex];
      this.axios({
        method: 'GET',
        url: '/v1/instagram/get/medias',
        params: {
          page: this.ig.page,
          limit: this.ig.limit,
          hashtag_id: currentTag.id,
          flags: [1],
        },
      })
        .then((response) => {
          this.ig.lastPage = response.data.list.meta.last_page;
          this.shapeMedias(response.data.list.data, refresh);
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
          this.ig.notFound = true;
        })
        .finally(() => {
          this.ig.loading = false;
        });
    },

    /**
     * template内で出力しやすいよう整形
     */
    shapeMedias(responseData, refresh) {
      if (!responseData.length) return;
      const showMedias = [];
      responseData.forEach((row) => {
        const hasMedia = row.ig_media_url || (row.children && row.children[0]);
        if (row.ig_media_type !== 'VIDEO' && hasMedia) {
          // 1枚のみ表示するためキーを統一する
          let mediaUrl = row.ig_media_url || null;
          if (row.children) {
            // children（CAROUSEL_ALBUM）の一枚目がVIDEOの場合もある
            row.children.some((d) => {
              if (d.ig_media_type === 'IMAGE') {
                mediaUrl = d.ig_media_url;
                return true;
              }
              return false;
            });
          }
          const data = {
            id: row.id,
            permalink: row.permalink,
            likeCount: row.like_count || 0,
            mediaUrl,
            mediaId: row.ig_media_id,
            mediaType: row.ig_media_type,
          };
          showMedias.push(data);
        }
      });

      if (refresh) {
        this.ig.medias = showMedias;
      } else {
        this.ig.medias = [...this.ig.medias, ...showMedias];
      }
    },

    /**
     * cards取得
     */
    getCards() {
      const params = {
        flags: [1],
        type: this.type,
        orderBy: 'order',
        order: 'asc',
      };

      this.axios({
        method: 'GET',
        url: '/v1/card/get/list',
        params,
      })
        .then((response) => {
          this.card.cards = response.data.cards.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
          this.card.notFound = true;
        })
        .finally(() => {
          this.card.loading = false;
        });
    },

    /**
     * もっと見るボタン押下
     */
    handleLoadMore() {
      if (this.ig.page < 2) {
        // 2ページ目（40件）まではload more
        this.ig.page += 1;
        this.getIgMedias(false);
      } else {
        // 3ページ目からinstagramの該当hashtagへリンク
        const currentHashtag = this.ig.hashtags[this.ig.tagIndex];
        const url = `https://www.instagram.com/explore/tags/${currentHashtag.ig_hashtag}/`;
        window.open(url, '_blank');
      }
    },

    /**
     * ハッシュタグの切り替え
     */
    changeHashtag(index) {
      this.ig.tagIndex = index;
      this.getIgMedias();
    },
  },
};
</script>

<style lang="scss" module="s">
.part {
  padding: 32px 65px;
  display: flex;
  flex-flow: column;
  align-items: center;
  .title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 32px;
  }
}

.area {
  &_title {
    font-weight: 700;
    padding-bottom: 4px;
    border-bottom: 1px solid var(--label-primary);
    margin-bottom: 12px;
    &.noRuby {
      padding-top: 5px;
    }
  }
  &_description {
    font-size: 12px;
  }
}

.stat {
  $pdg: 32px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  &_title {
    font-weight: 700;
    text-align: center;
    font-size: 20px;
    padding-bottom: 8px;
    border-bottom: 2px solid var(--label-primary);
    margin-bottom: 16px;
  }
  .people {
    width: calc(40% - $pdg / 2);
    text-align: center;
    &_list {
      width: fit-content;
      margin: 0 auto 12px;
      padding-top: 8px;
      display: flex;
      flex-flow: column;
      align-items: flex-start;
    }
  }
  .climate {
    width: calc(60% - $pdg / 2);
    &_content {
      display: flex;
      justify-content: space-between;
      $mgn: 16px;
      > :first-child {
        width: calc(45% - $mgn / 2);
      }
      > :last-child {
        width: calc(55% - $mgn / 2);
      }
    }
    &_description {
      padding-top: 8px;
    }
    &_list {
      padding: 8px 12px;
      background-color: var(--surface-point-gray);
      border-radius: 8px;
    }
  }
}

.poster {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: column;
}

.traffic {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .map {
    width: 50%;
  }
  .imgs {
    width: 45%;
  }
}


.taglist {
  > li {
    flex-shrink: 0;
  }
}

.insta {
  &_label {
    font-weight: 700;
    margin-bottom: 24px;
  }
  &_link {
    overflow: hidden;
    border-radius: 20px;
    position: relative;
    display: block;
    &:hover {
      .insta_item {
        &:before {
          opacity: 1;
        }
      }
      .insta_like_count {
        opacity: 1;
      }
    }
  }
  &_item {
    width: 100%;
    padding-top: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    z-index: 0;
    transition: all .4s;
    &:before {
      content: "";
      opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(black, .6);
      z-index: 1;
    }
  }
  &_like_count {
    position: absolute;
    bottom: .5em;
    right: 1em;
    z-index: 2;
    opacity: 0;
    color: white;
    svg {
      fill: white;
      margin-right: .2em;
    }
  }

  &_account {
    display: flex;
    flex-flow: column;
    align-items: center;
    .name {
      margin-top: 16px;
      word-break: break-all;
    }
  }
}


.darken {
  mix-blend-mode: darken;
}
.center {
  margin: 0 auto;
}
.alignCenter {
  text-align: center;
}
.bold {
  font-weight: 700;
}
.linespaced {
  line-height: 2;
}
.center {
  text-align: center;
}

@include smView {
  .part {
    padding: 32px 20px;
    .sp_sm {
      font-size: 14px;
    }
    .traffic {
      display: block;
      > * {
        width: 100%;
      }
      .imgs {
        margin-top: 20px;
        > * {
          margin: 0 auto;
        }
      }
    }
  }

  .stat {
    display: block;
    .people, .climate {
      width: 100%;
    }
    .climate {
      margin-top: 36px;
    }
  }

  .taglist {
    margin-left: -20px;
    padding-left: 20px;
  }

  .insta {
    &_link {
      border-radius: 14px;
    }
  }
}
</style>
