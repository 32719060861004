<template>
  <div
    :class="s.wrap"
    :style="`background-image: url('${url}');`" />
</template>

<script>
export default {
  name: 'circle-img',
  props: {
    url: {
      type: String,
      default: '/images/dammy.png',
    },
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  padding-top: 100%;
  width: 100%;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #eeeeee;
}
</style>
