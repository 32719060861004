<template>
  <div>
    <ul :class="s.list">
      <li v-for="(p, i) in policy" :key="i">
        <div :class="s.label">{{ p.label }}</div>
        <div
          :class="s.content"
          v-html="p.content"/>
        <ul>
          <li v-for="(list, i) in p.desclist" :key="i">{{ list }}</li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'policy',
  props: {
    policy: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" module="s">
.list {
  > li {
    &:not(:first-child) {
      margin-top: 32px;
    }
  }
}
.label {
  font-weight: 700;
}
.content {

}
</style>
