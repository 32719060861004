<template>
  <div :style="style" :class="[s.wrap, hasShadow ? s.shadow : '']">
    <div :class="s.img_wrap">
      <div :class="s.img" :style="img ? `background-image: url(${img});` : 'backgound-color: rgba(0, 0, 0, .2)'"/>
    </div>
    <div :class="[s.content_wrap, s[contentBg]]">
      <div>
        <div
          v-if="date"
          :class="s.date">{{ date }}</div>
        <div
          v-if="name"
          :class="s.name">{{ name }}</div>
        <div
          v-if="title"
          :class="s.title">{{ title }}</div>
        <div 
          v-if="content"
          :class="s.content"
          class="n2br"
          v-html="content"/>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'content-card',
  props: {
    radius: {
      type: Number,
      default: 0,
    },
    // 横幅基準で高さを何％にするか
    imgRatio: {
      type: Number,
      default: 100,
    },
    smImgRatio: {
      type: Number,
      default: 50,
    },
    imgBorderRadius: {
      type: Number,
      default: 0,
    },
    img: {
      type: String,
    },
    date: {
      type: String,
    },
    title: {
      type: String,
    },
    name: {
      type: String,
    },
    content: {
      type: String,
    },
    contentSidePadding: {
      type: Number,
      default: 16,
    },
    contentBg: {
      type: String,
      default: 'transparent',
      validator: (value) => ['transparent', 'wht'].includes(value),
    },
    fontSize: {
      type: Number,
      default: 14,
    },
    hasShadow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    style() {
      return {
        '--radius': `${this.radius}px`,
        '--img-ratio': `${this.imgRatio}%`,
        '--img-ratio-sm': `${this.smImgRatio}%`,
        '--img-radius': `${this.imgBorderRadius}px`,
        '--side-padding': `${this.contentSidePadding}px`,
        '--font-size': `${this.fontSize}px`,
      };
    },
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  color: var(--label-primary);
  border-radius: var(--radius);
  overflow: hidden;
  display: flex;
  flex-flow: column;
  &.shadow {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
}
.img {
  &_wrap {
    width: 100%;
  }
  width: 100%;
  padding-top: var(--img-ratio);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: var(--img-radius);
  @include smView {
    padding-top: var(--img-ratio-sm);
  }
}

.content {
  &_wrap {
    flex: 1;
    text-align: start;
    padding: 0 var(--side-padding) var(--side-padding);

    &.wht {
      background-color: #ffffff;
    }

    .date {
      font-size: 14px;
      opacity: .5;
      padding-top: 8px;
    }
    .title {
      font-weight: 700;
      padding: 12px 0;
      text-align: center;
    }
    .name {
      font-weight: 700;
      padding: 8px 0;
    }
  }
  font-size: var(--font-size);
}
</style>
