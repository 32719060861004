<template>
  <div class="interview">
    <SectionContainer>
      <div v-if="!content && !flag.isLoading">無効なURLです。</div>
      <SectionFrame
        v-if="content"
        :marginTop="112">
        <div v-if="content.medias.length" :class="s.img" :style="`background-image: url(${content.medias[0].url})`"/>
        <div :class="s.caption" v-if="content?.medias[0]?.caption">{{ content.medias[0].caption }}</div>
        <spacer :y="8"/>
        <div :class="s.name">{{ content.title }}</div>
        <div :class="s.date">{{ formatTimestamp(content.interview_date, 'YYYY年MM月DD日') }}</div>
        <div :class="s.description">{{ content.summary }}</div>
        <div
          class="basedata"
          v-if="convertBasedata(content.basedata).length">
          <div>【基本情報】</div>
          <ul>
            <li v-for="(item, i) in convertBasedata(content.basedata)" :key="i">
              <div v-if="item.type === 'link'">
                <a :href="item.value" target="_blank">{{ item.label }}</a>
              </div>
              <div v-else>
                <div>【{{ item.label }}】</div>
                <div v-html="item.value" class="n2br"/>
              </div>
            </li>
          </ul>
        </div>
        <div v-html="content.content"/>
        <spacer :y="10"/>
      </SectionFrame>
    </SectionContainer>
    <SectionContainer>
      <SectionFrame
        :title="'ほかのインタビュー'"
        :titleBottomMargin="48"
        v-if="otherInterviews.length">
        <FlexList
          :spaceX="40"
          :spaceY="32"
          :align="'center'"
          :count="2"
          :smCount="1"
          :wrap="true">
          <li v-for="item of otherInterviews" :key="item.id">
            <router-link :to="`/interview/${item.open_id}/`" :class="s.itemLink">
              <ContentCard
                :img="item.medias[0]?.url"
                :imgRatio="70"
                :smImgRatio="70"
                :date="formatTimestamp(item.interview_date, 'YYYY.MM.DD')"
                :name="item.title"
                :content="item.summary"
                :contentBg="'wht'"
                :radius="28"
                :hasShadow="true">
                <spacer :y="1"/>
                <FlexList
                  :spaceX="12"
                  :spaceY="12"
                  :wrap="true">
                  <li v-for="tag in item.tags" :key="tag.id">
                    <tag-btn
                      :size="'sm'"
                      :type="'category'">{{ tag.label }}</tag-btn>
                  </li>
                </FlexList>
              </ContentCard>
            </router-link>
          </li>
        </FlexList>
      </SectionFrame>
    </SectionContainer>
    <spacer :y="12"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  SectionContainer,
  SectionFrame,
  Spacer,
  FlexList,
  ContentCard,
  TagBtn,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'interview-detail',
  mixins: [cf],
  components: {
    SectionContainer,
    SectionFrame,
    Spacer,
    FlexList,
    ContentCard,
    TagBtn,
  },
  data() {
    return {
      flag: {
        isLoading: true,
      },
      content: null,
      otherInterviews: [],
    };
  },
  computed: {
    ...mapState(['helper']),
    openId() {
      return this.$route.params.open_id;
    },
  },
  created() {
    this.get();
    this.getOther();
  },
  watch: {
    $route() {
      this.get();
      this.getOther();
    },
  },
  methods: {
    convertBasedata(basedata) {
      if (!basedata) return [];
      try {
        return JSON.parse(basedata);
      } catch {
        return [];
      }
    },
    get() {
      const params = {
        open_id: this.openId,
      };

      this.axios({
        method: 'GET',
        url: '/v1/interview/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.content = res.detail;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
    getOther() {
      const params = {
        excludeOpenId: this.openId,
        limit: 2,
      };

      this.axios({
        method: 'GET',
        url: '/v1/interview/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.otherInterviews = res.interviews.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" module="s">
.img {
  width: 100%;
  padding-top: 66%;
  background-size: cover;
  border-radius: 28px;
  background-position: center;
}

.caption {
  font-size: 14px;
  color: var(--label-secondary);
  text-align: right;
  margin-top: 12px;
}

.name {
  font-weight: 700;
  font-size: 24px;
}

.date {
  font-size: 12px;
}

.description {
  margin-top: 24px;
  color: var(--label-secondary);
}

.itemLink {
  display: block;
  height: 100%;
  > * {
    height: 100%;
  }
}
</style>
