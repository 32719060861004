<template>
  <div>
    <div class="topics">
      <SectionContainer>
        <SectionFrame
          v-if="item"
          :title="item.title"
          :titleBottomMargin="56"
          :marginTop="48">
          <img
            :class="s.medias"
            :src="item.medias[0].url"
            alt=""
            v-if="item.medias?.length">
          <div class="n2br" v-html="item.summary"/>
          <div
            class="n2br content"
            v-html="item.content"/>
        </SectionFrame>
      </SectionContainer>
    </div>
    <spacer :y="10"/>
    <FlexList :align="'center'" v-if="type">
      <basic-btn
        :link="`/${type}/topics/`"
        :tag="'a'">
        <span v-html="$route.meta.title"/>の一覧へ
      </basic-btn>
    </FlexList>
    <spacer :y="20"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  SectionContainer,
  SectionFrame,
  BasicBtn,
  FlexList,
  Spacer,
} from '@/components/parts';

export default {
  name: 'topics-detail',
  components: {
    SectionContainer,
    SectionFrame,
    BasicBtn,
    FlexList,
    Spacer,
  },
  data() {
    return {
      pageMetas: [
        {
          name: 'kaigyou',
          title: 'お役立ちトピックス\n（店舗開業・起業のすすめ）',
          description: '桐生市で「店舗開業・起業」のために役立つコンテンツをまとめ、随時更新しています。',
          headColor: 'yellow',
        },
        {
          name: 'sumu',
          title: 'お役立ちトピックス\n（住む）',
          description: '桐生市で「住む」ために役立つコンテンツをまとめ、随時更新しています。',
          headColor: 'red',
        },
        {
          name: 'hataraku',
          title: 'お役立ちトピックス\n（働く）',
          description: '桐生市で「働く」ために役立つコンテンツをまとめ、随時更新しています。',
          headColor: 'yellowgreen',
        },
        {
          name: 'kosodate',
          title: 'お役立ちトピックス\n（育てる）',
          description: '桐生市で「育てる」ために役立つコンテンツをまとめ、随時更新しています。',
          headColor: 'blue',
        },
      ],
      item: null,
    };
  },
  mounted() {
    this.setPageData();
  },
  computed: {
    ...mapState(['helper', 'page']),
    type() {
      if (!this.item?.category?.length) return null;
      return this.item.category[0].name;
    },
  },
  watch: {
    type() {
      this.setPageData();
    },
  },
  created() {
    this.get();
  },
  methods: {
    setPageData() {
      if (!this.type || !this.item) return;
      const page = this.pageMetas.find((p) => p.name === this.type);
      if (!page) return;

      // detailHeadの情報変更
      this.$store.dispatch('page/setPageData', page);
    },
    get() {
      const params = {
        slug: this.$route.params.slug,
      };

      this.axios({
        method: 'GET',
        url: '/v1/topic/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.item = res.detail;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" module="s">
.media {
  width: 100%;
  object-fit: contain;
}
</style>
