<template>
  <div>
    <SectionContainer id="step">
      <SectionFrame
        :title="'移住へのステップ'"
        :description="'桐生市への移住のステップをご紹介します。\nむすびすむ桐生では、移住コーディネーターが不安の多い移住検討過程から移住後の暮らしまで、伴走支援します。'"
        :marginTop="112"
        :smMarginTop="80">
        <SimpleList
          v-if="card.cards?.length"
          :margin="24">
          <li
            :class="s.steps"
            v-for="(step, i) in adjustCards(card.cards, 'steps')"
            :key="i">
            <div :class="s.step"><span>{{ '0' + (i + 1) }}</span></div>
            <div :class="s.title">{{ step.title }}</div>
            <div
              :class="s.description"
              v-html="step.summary"/>
            <div
              v-if="step.checks?.length"
              :class="s.checks_label">チェックリスト</div>
            <ul :class="s.checks" v-if="step.checks?.length">
              <li v-for="(check, i) in step.checks" :key="i">{{ check }}</li>
            </ul>
            <FlexList
              v-if="step.buttons?.length || step.link"
              :spaceX="16"
              :spaceY="16"
              :wrap="true"
              :class="s.btns">
              <!-- 初期実装 -->
              <li v-for="(btn, i) in step.buttons" :key="i">
                <basic-btn
                  :size="'sm'"
                  :type="'bdr'"
                  :width="'fit-content'"
                  :tag="btn.link ? 'a' : 'button'"
                  :link="btn.link ? btn.link : ''"
                  :openOtherTab="btn.openOtherTab">{{ btn.label }}</basic-btn>
              </li>
              <!-- supportsはlinkにシリアライズされた配列が渡ってくる -->
              <li v-for="(btn, i) in adjustedLink(step.link)" :key="i">
                <basic-btn
                  :size="'sm'"
                  :type="'bdr'"
                  :width="'fit-content'"
                  :tag="btn.value ? 'a' : 'button'"
                  :link="btn.value"
                  :openOtherTab="true">{{ btn.label }}</basic-btn>
              </li>
            </FlexList>
          </li>
        </SimpleList>
      </SectionFrame>
    </SectionContainer>

    <SectionContainer id="window">
      <SectionFrame
        :title="'移住支援窓口・コーディネーター体制'"
        :description="'先輩移住者の夫婦や店舗開業者、まちの仕掛け人、アパレルデザイナー・クリエイター、不動産関係者、アクティブな暮らしに詳しい方など、多種多様な9組11名の移住コーディネーターが、最適な関係者につなげる、伴走型の相談支援を実施します。<br>まずは、一度お気軽にお問い合わせください。'">
        <Bg :bgcolor="'gray'" :radius="28">
          <div :class="s.contact_wrap">
            <div :class="s.contact_label">選べる相談・お問い合わせ方法</div>
            <ul :class="s.contact">
              <li v-for="(ct, i) in contacts" :key="i">
                <div :class="s.label">{{ ct.label }}</div>
                <div v-html="ct.description" :class="s.description" class="n2br"/>
                <div v-if="ct.tel" :class="s.tel">{{ ct.tel }}</div>
                <FlexList :align="'center'" :class="s.btns" v-if="ct.btns">
                  <li v-for="(btn, i) in ct.btns" :key="i">
                    <basic-btn
                      :tag="'a'"
                      :link="btn.link"
                      :size="'sm'"
                      :width="'fit-content'">{{ btn.label }}</basic-btn>
                  </li>
                </FlexList>
                <div v-html="ct.other" v-if="ct.other" :class="s.other" class="n2br"/>
              </li>
            </ul>
          </div>
        </Bg>
      </SectionFrame>
    </SectionContainer>

    <SectionContainer>
      <SectionFrame
        :title="'移住コーディネーター（窓口・チーフ）'"
        :maxWidth="1040">
        <FlexList
          :spaceX="40"
          :smSpaceX="32"
          :spaceY="48"
          :count="4"
          :smCount="1"
          :wrap="true"
          :align="'center'">
          <li v-for="cordinator of filteredCordinators(1)" :key="cordinator.id">
            <Cordinator :cordinator="cordinator"/>
          </li>
        </FlexList>
      </SectionFrame>
    </SectionContainer>

    <SectionContainer>
      <SectionFrame
        :title="'移住コーディネーター（地域）'"
        :maxWidth="1040">
        <FlexList
          :align="'center'"
          :wrap="true"
          :count="4"
          :spaceX="40"
          :smSpaceX="32"
          :spaceY="48"
          :smCount="1">
          <li v-for="cordinator of filteredCordinators(2)" :key="cordinator.id">
            <Cordinator :cordinator="cordinator"/>
          </li>
        </FlexList>
      </SectionFrame>
    </SectionContainer>

    <SectionContainer id="support">
      <SectionFrame
        :title="'移住支援制度'"
        :description="'あなたの移住・定住を応援する支援メニューを用意しています。'">
        <SimpleList
          v-if="card.cards?.length"
          :hasLine="false"
          :margin="32">
          <BgListItem
            v-for="item in adjustCards(card.cards, 'supports')"
            :key="item"
            :bgcolor="'gray'">
              <ContentListFrame :item="item" />
          </BgListItem>
        </SimpleList>
      </SectionFrame>
    </SectionContainer>

    <SectionContainer id="simulation">
      <SectionFrame
        :title="'桐生暮らしシミュレーション'"
        :description="'桐生市に移住すると対象になり得る支援をシミュレーションしてみよう。'"
        :descriptionSize="'lg'">
        <SimulationBanners name="shien"/>
      </SectionFrame>
    </SectionContainer>

    <spacer :y="20" :smY="15"/>
    <Interview
      id="interview"
      :category="categoryId" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  Spacer,
  SectionFrame,
  BasicBtn,
  ContentListFrame,
  Bg,
  Cordinator,
  SimpleList,
  SectionContainer,
  FlexList,
  Interview,
  BgListItem,
  SimulationBanners,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'support-living',
  components: {
    Spacer,
    BgListItem,
    SectionFrame,
    BasicBtn,
    ContentListFrame,
    Bg,
    Cordinator,
    SimpleList,
    SectionContainer,
    FlexList,
    Interview,
    SimulationBanners,
  },
  mixins: [cf],
  data() {
    return {
      card: {
        loading: true,
        notFound: false,
        cards: [],
      },
      steps: [
        {
          step: '01',
          title: '思い立ったら情報収集開始！',
          description: 'はじめに、なぜ移住したいと考えているのか、その目的を整理することが大切です。\n自分の目指すライフスタイルを思い浮かべながら、WEBやSNSなどから情報を集めましょう。\n家族がいる場合は、しっかり話し合いながら検討を進めましょう。\n\n【むすびすむ桐生では】\n・このポータルサイトでは移住の目的・不安に合わせた最新情報やリアルな暮らし情報のチェックが可能です。\n・SMOUTでは、気軽に参加できるイベントやセミナーの情報も随時お知らせします。',
          btns: [
            {
              label: '移住支援ポータルサイト 「むすびすむ桐生」',
              link: '/',
            },
            {
              label: '移住スカウトサービス「SMOUT」桐生市ページ',
              link: 'https://smout.jp/areas/506',
              openOtherTab: true,
            },
          ],
        },
        {
          step: '02',
          title: 'まずは問い合わせ、話を聞いてみよう！',
          description: '調べていく中で気になることがあったら、まずは一度問い合わせをしてみましょう。WEB上の情報だけでは分からないことも多く、直接聞いたり話すことで気づいていなかった興味や不安が見つかります。\n\n【むすびすむ桐生では】\n・あなたに合わせた多彩な移住コーディネーターによる伴走支援をします。\n・桐生のリアルな暮らしから傾向、最新情報まで、対面・オンラインでいつでも相談可能です。',
          btns: [
            {
              label: '移住コーディネーター体制紹介',
              link: '/shien/#window',
            },
            {
              label: '相談・問い合わせはこちらから',
              link: '/contact/',
            },
          ],
        },
        {
          step: '03',
          title: '一度現地に遊びに行ってみよう！',
          description: '興味が高まってきたら、百聞は一見にしかず、実際に現地に行ってみましょう。まずは旅行として気軽に訪れるのも良いと思います。やはり移住先の雰囲気を実際に肌で体感することで、理想とのギャップを埋めることにつながります。\n\n【むすびすむ桐生では】\n・興味に合わせて、おすすめスポットのご紹介や先輩移住者と話せる場もセッティングします。\n・イベントが多い第一土曜日がおすすめ、桐生での店舗開業や暮らしを体験できるツアーも開催予定です。\n・お試し暮らし助成として、移住相談をした後に桐生市内の古民家や宿坊、温泉旅館に泊まると補助が受けられます。',
          btns: [
            {
              label: '桐生を体験できるイベント開催予定（SMOUT）',
              link: 'https://smout.jp/areas/506',
              openOtherTab: true,
            },
            {
              label: '第一土曜日の桐生三大市',
              link: 'https://www.city.kiryu.lg.jp/kankou/event/1010676/index.html',
              openOtherTab: true,
            },
            {
              label: 'お試し暮らし助成 ',
              link: 'https://www.city.kiryu.lg.jp/shisei/machi/iinekiryu/1004345.html',
              openOtherTab: true,
            },
          ],
        },
        {
          step: '04',
          title: '移住先での働き方、暮らし方を具体的に検討しよう！ ',
          description: 'いざ真剣に考えると、働き方や住まい、子育てのことなど、考えなければならないことはたくさん出てきて、頭を悩ませることも多いと思います。移住先でどんな暮らしを実現したいか、変化やチャレンジを楽しめるか、じっくり考えた上で、最終的な結論を出しましょう。\n\n【むすびすむ桐生では】\n・移住コーディネーターが不安や悩みをひとつひとつ解消します。\n・働き方、暮らし方に合わせた支援制度の紹介や支援機関につなぎます。\n・先輩移住者や創業・店舗開業者にもつなぐことができます。直接話すことでリアルな暮らしのイメージができます。',
          btns: [
            {
              label: '店舗開業・起業のすすめ',
              link: '/kaigyou/',
            },
            {
              label: '働く',
              link: '/hataraku/',
            },
            {
              label: '住む',
              link: '/sumu/',
            },
            {
              label: '育てる',
              link: '/kosodate/',
            },
          ],
        },
        {
          step: '05',
          title: '移住先での暮らし始まり',
          description: '引越し、手続きを終えて、移住先での新生活がスタート。\n\n【むすびすむ桐生では】 \n・移住検討中に、ゆるやかに・ほどよく様々な方につながることで、移住初日から知り合い・なじみができた環境で安心した暮らしをスタートできます。\n・移住後も、暮らしの中で、分からない点や不安な点があれば、いつでも相談できます。',
        },
      ],
      contacts: [
        {
          label: '①お問い合わせフォーム（WEB）',
          description: '以下の「むすびすむ桐生」専用フォームからお問い合わせください。\n内容を確認し、窓口の移住コーディネーターから返信させていただきます。',
          btns: [
            {
              label: 'お問い合わせフォーム',
              link: '/contact',
            },
          ],
        },
        {
          label: '②専用電話',
          description: '以下の「むすびすむ桐生」専用番号までお電話ください。\nコーディネーターが相談対応や外出時など、電話に出られない場合には、お手数ですがご伝言を残していただけると助かります。',
          tel: '050-3529-6573',
          other: '平日・土日祝 10:00〜17:00\n休業日：年末年始',
        },
        {
          label: '③直接窓口へ',
          description: '以下の窓口へ直接お越しください。\n※事前にご連絡をいただけますと、よりスムーズな対応ができます。',
          other: '桐生市移住支援フロント「むすびすむ桐生」\n〒376-0031\n群馬県桐生市本町５丁目51番地東武桐生ビル１階cocotomo内\n平日・土日祝 10:00〜17:00休業日：年末年始\n<iframe src="//www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3210.9418613236458!2d139.3390005!3d36.410601899999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601ee246b92c0dab%3A0x6e0df46f281ef5!2z44Kz44Ov44O844Kt44Oz44Kw44O744Kz44Of44Ol44OL44OG44Kj44K544Oa44O844K544CMQ09DT1RPTU_jgI0!5e0!3m2!1sja!2sjp!4v1690767361195!5m2!1sja!2sjp" height="200" style="border: 2px solid rgba(0, 0, 0, 0.32); border-radius: 28px; margin-top: 32px; max-width: 400px; width: 100%;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',
        },
      ],
    };
  },
  created() {
    this.getCards();
  },
  computed: {
    ...mapState(['helper']),
    cordinators() {
      return this.helper.master?.cordinator;
    },
    type() {
      return this.$route.meta.name || null;
    },
    categoryId() {
      return this.helper?.categories?.find((d) => d.name === this.type)?.id || null;
    },
  },
  methods: {
    filteredCordinators(type) {
      if (!this.cordinators) return [];
      return this.cordinators.filter((c) => c.type === type);
    },
    getCards() {
      const params = {
        flags: [1],
        type: this.type,
        orderBy: 'order',
        order: 'asc',
      };

      this.axios({
        method: 'GET',
        url: '/v1/card/get/list',
        params,
      })
        .then((response) => {
          this.card.cards = response.data.cards.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
          this.card.notFound = true;
        })
        .finally(() => {
          this.card.loading = false;
        });
    },

    adjustedLink(str) {
      try {
        return JSON.parse(str);
      } catch {
        return [];
      }
    },
  },
};
</script>

<style lang="scss" module="s">
.steps {
  position: relative;
  padding: 32px 80px;
  background-color: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
  border-radius: 28px;
  overflow: hidden;
  .step {
    background: linear-gradient(134deg, #61C0B0 0%, #ADCCC7 100%, #59B2A4 100%);
    color: #ffffff;
    font-weight: 700;
    position: absolute;
    width: 124px;
    height: 124px;
    top: -53px;
    left: -45px;
    border-radius: 50%;
    font-size: 28px;
    span {
      display: block;
      position: absolute;
      top: 62px;
      left: 62px;
    }
  }
  .title {
    font-size: 18px;
    font-weight: 700;
  }
  .description {
    margin-top: 14px;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  .checks {
    &_label {
      margin-top: 1em;
    }
    // display: flex;
    flex-wrap: wrap;
    > li {
      padding-left: 20px;
      position: relative;
      margin-right: 16px;
      &::before, &::after {
        content: '';
        position: absolute;
      }
      &::before {
        width: 16px;
        height: 16px;
        top: 50%; left: 0;
        transform: translate(0, -42%);
        background-color: #cccccc;
        border-radius: 4px;
      }
      &::after {
        width: 14px;
        height: 7px;
        top: 50%; left: 0;
        transform: translate(3px, -65%) rotate(-35deg);
        border-left: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
        box-shadow: rgba(0, 0, 0, 0.15) -2px 2px 2px;
      }
    }
  }
  .btns {
    margin-top: 16px;
  }
}

.contact {
  &_wrap {
    padding: 40px;
  }
  &_label {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 32px;
  }

  font-size: 12px;
  text-align: center;

  > li {
    text-align: center;
    &:not(:first-child) {
      margin-top: 32px;
    }
  }
  .label {
    font-size: 16px;
    font-weight: 700;
  }
  .description {
    margin-top: 12px;
  }
  .tel {
    margin-top: 12px;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 8px;
  }
  .other {
    margin-top: 12px;
  }

  .btns {
    margin-top: 12px;
  }
}

@include smView {
  .steps {
    padding: 40px;
    .step {
      width: 69px;
      height: 69px;
      top: -24px;
      left: -24px;
      span {
        top: 30px;
        left: 30px;
        font-size: 20px;
      }
    }
    .title {
      font-size: 16px;
    }
    .description {
      font-size: 12px;
    }
    .checks {
      font-size: 12px;
      &_label {
        font-size: 12px;
      }
    }
  }
}
</style>
