<template>
  <div>
    <SimpleList :margin="16">
      <SimpleListItem
        v-for="item in lists"
        :key="item.id"
        :img="item.medias?.[0] ? item.medias[0].url : null"
        :requireImg="true"
        :link="item.link"
        :target="item.link && item.link.includes(baseUrl) ? '_self' : '_blank'">
        <template v-slot:head>
          <div :class="s.head" v-if="!['lg', 'md'].includes(layout.displaySize)">
            <div :class="s.category" v-if="item.category?.length">{{ item.category[0].label }}</div>
            <div :class="s.date">{{ formatTimestamp(item.publish_date, 'YYYY年MM月DD日') }}</div>
          </div>
        </template>
        <template v-slot:content>
          <div :class="s.head" v-if="['lg', 'md'].includes(layout.displaySize)">
            <div :class="s.category" v-if="item.category?.length">{{ item.category[0].label }}</div>
            <div :class="s.date">{{ formatTimestamp(item.publish_date, 'YYYY年MM月DD日') }}</div>
          </div>
          <div :class="s.body">
            <p>{{ item.title }}</p>
          </div>
        </template>
      </SimpleListItem>
    </SimpleList>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SimpleList from './SimpleList.vue';
import SimpleListItem from './SimpleListItem.vue';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'info-list',
  mixins: [cf],
  props: {
    limit: {
      type: Number,
      default: 999999,
    },
  },
  components: {
    SimpleList,
    SimpleListItem,
  },
  data() {
    return {
      lists: [],
    };
  },
  computed: {
    ...mapState(['layout']),
    baseUrl() {
      return location.origin;
    },
  },
  created() {
    this.get();
  },
  methods: {
    get() {
      const params = {
        orderBy: 'publish_date',
        limit: this.limit,
        isBeforeToday: 1,
      };

      this.axios({
        method: 'GET',
        url: '/v1/information/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.lists = res.informations.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" module="s">
.head {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  @include smView {
    margin-bottom: 8px;
  }
}
.category {
  padding: 4px 16px;
  font-size: 14px;
  border: 1px solid var(--line-thin);
  color: var(--label-primary);
}
.date {
  color: var(--label-primary);
  opacity: .5;
  font-size: 14px;
  margin-left: 16px;
}
.body {
  text-align: start;
}
</style>
