<template>
  <div>
    <SectionContainer id="charm">
      <SectionFrame
        :title="'桐生で育てる魅力'"
        :descriptionSize="'lg'"
        :maxWidth="1040"
        :marginTop="112">
        <FlexList
          :spaceX="40"
          :spaceY="32"
          :count="4"
          :smCount="1"
          :align="'center'"
          :wrap="true">
          <li v-for="(item, i) in charms" :key="i">
            <ContentCard
              :imgBorderRadius="28"
              :img="item.media"
              :title="item.label"
              :content="item.content"
              :contentSidePadding="0"
              :fontSize="12">
            </ContentCard>
          </li>
        </FlexList>
        <Spacer :y="4"/>
        <FlexList :align="'center'">
          <li><basic-btn :tag="'a'" :link="'/contact/'">移住コーディネーターに問い合わせてみる</basic-btn></li>
        </FlexList>
      </SectionFrame>
    </SectionContainer>

    <SectionContainer id="topics">
      <SectionFrame
        :title="'お役立ちトピックス'"
        :description="'桐生市で「育てる」ために役立つコンテンツをまとめ、随時更新しています。'"
        :descriptionSize="'lg'">
        <Bg :bgcolor="'gray'" :radius="28">
          <TopicList
            type="kosodate"
            :limit="5" />
          <FlexList :align="'center'">
            <basic-btn
              :tag="'a'"
              :link="'/kosodate/topics/'">もっと見る</basic-btn>
          </FlexList>
          <spacer :y="4"/>
        </Bg>
      </SectionFrame>
    </SectionContainer>

    <SectionContainer id="simulation">
      <SectionFrame
        :title="'桐生暮らしシミュレーション'"
        :description="'自分がこれから桐生市に移住する場合、どんな支援が対象になるのか、また自分の好みや条件だと、どんな桐生での暮らし方ができるのか、シミュレーションしてみましょう。'"
        :descriptionSize="'lg'">
        <SimulationBanners name="kosodate"/>
      </SectionFrame>
    </SectionContainer>

    <SectionContainer id="education">
      <SectionFrame
        :title="'特長的な教育・学び'"
        :description="'国立大学法人群馬大学との連携プログラムなど、桐生ならではの地域の特性を生かした教育を紹介します。'"
        :descriptionSize="'lg'">
        <SimpleList
          :hasLine="false"
          :margin="32">
          <BgListItem
            v-for="item in adjustCards(card.cards, 'educations')"
            :key="item"
            :bgcolor="'gray'">
              <ContentListFrame :item="item" />
          </BgListItem>
        </SimpleList>
      </SectionFrame>
    </SectionContainer>

    <SectionContainer id="various-education">
      <SectionFrame
        :title="'様々な学びの場'"
        :description="'桐生市には、子育てに適した様々な学びの場があります。'"
        :descriptionSize="'lg'">
        <SimpleList
          :hasLine="false"
          :margin="32">
          <BgListItem
            v-for="item in adjustCards(card.cards, 'learningOpportunities')"
            :key="item"
            :bgcolor="'gray'">
              <ContentListFrame :item="item" />
          </BgListItem>
        </SimpleList>
      </SectionFrame>
    </SectionContainer>

    <SectionContainer id="support">
      <SectionFrame
        :title="'子育てに関連する支援制度や遊べる施設'"
        :description="'子育て支援や子育てに関する相談ができる施設、遊びに行ける施設があります。'"
        :descriptionSize="'lg'">
        <SimpleList
          :hasLine="false"
          :margin="32">
          <BgListItem
            v-for="item in adjustCards(card.cards, 'supports')"
            :key="item"
            :bgcolor="'gray'">
              <ContentListFrame :item="item" />
          </BgListItem>
        </SimpleList>
      </SectionFrame>
    </SectionContainer>

    <spacer :y="20" :smY="15"/>
    <Interview
      id="interview"
      :category="categoryId"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  Spacer,
  SectionFrame,
  BasicBtn,
  ContentListFrame,
  Bg,
  SectionContainer,
  SimpleList,
  FlexList,
  ContentCard,
  Interview,
  TopicList,
  BgListItem,
  SimulationBanners,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'support-living',
  components: {
    TopicList,
    BgListItem,
    Spacer,
    SectionFrame,
    BasicBtn,
    ContentListFrame,
    Bg,
    SectionContainer,
    SimpleList,
    FlexList,
    ContentCard,
    Interview,
    SimulationBanners,
  },
  mixins: [cf],
  data() {
    return {
      charms: [
        {
          label: '充実した子育て環境・支援',
          content: '保育園・放課後児童クラブの待機児童ゼロはもちろん、18歳までの子供の医療費は完全無料など支援制度も充実しています。',
          media: '/images/detail/raising_charm_1.jpg',
        },
        {
          label: '様々な学びの場',
          content: '大自然の中にある小中一貫コミュニティスクール、オルタナティブスクール、市内7校の高校・特別支援学校高等部に国立大学まで、個性を育み、地域で育てる桐生ならではの学びの環境が整っています。',
          media: '/images/detail/raising_charm_2.jpg',
        },
        {
          label: '豊富なお出かけ先',
          content: '入場料無料の動物園・遊園地から、公園や山や川などの自然体験、そして市内各地で毎週のように開催されるマルシェや子供向けイベントなど、楽しい週末を過ごすことができます。',
          media: '/images/detail/raising_charm_3.jpg',
        },
      ],
      card: {
        loading: true,
        notFound: false,
        cards: [],
      },
    };
  },
  created() {
    this.getCards();
  },
  computed: {
    ...mapState(['helper']),
    type() {
      return this.$route.meta.name || null;
    },
    categoryId() {
      return this.helper?.categories?.find((d) => d.name === this.type)?.id || null;
    },
  },
  methods: {
    getCards() {
      const params = {
        flags: [1],
        type: this.type,
        orderBy: 'order',
        order: 'asc',
      };

      this.axios({
        method: 'GET',
        url: '/v1/card/get/list',
        params,
      })
        .then((response) => {
          this.card.cards = response.data.cards.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
          this.card.notFound = true;
        })
        .finally(() => {
          this.card.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" module="s">
</style>
