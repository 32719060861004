<template>
  <div :class="s.wrap">
    <img :class="s.arc" src="/images/layout/top_arc.svg" alt="">
    <div :class="s.gradation_wrap">
      <div :class="s.gradation" />
    </div>
    <SectionContainer :class="s.inner">
      <SectionFrame
        :title="'移住者インタビュー'"
        :description="'IターンやUターン、店舗開業や独立、様々なきっかけや後押しを経て、桐生で暮らす人たちの声を少しずつお届けします。'"
        :marginTop="0"
        :smMarginTop="40"
        :maxWidth="1080">
        <ul :class="s.tag_list" v-if="adjustedTags.length">
          <li
            v-for="(tagGroup,i) in adjustedTags"
            :key="i">
            <div :class="s.tag_group_name">{{ tagGroup.name }}</div>
            <div
              :class="s.tag_group_link"
              v-if="tagGroup.name === 'エリア'">
              桐生市内の各エリア紹介は<router-link to="/about/">こちら</router-link>
            </div>
            <TagList v-if="adjustedTags.length" ref="tagList">
              <li v-for="tag of tagGroup.list" :key="tag.id">
                <tag-btn
                  :isActive="adjustSelectedTags.includes(tag.id)"
                  :shape="'straight'"
                  :type="'check'"
                  @click="clickSearchTags(tag)">{{ tag.label }}</tag-btn>
              </li>
            </TagList>
          </li>
        </ul>
        <Spacer :y="6"/>
        <Loader
          :flag="!flag.loaded">
          <FlexList
            :spaceX="40"
            :spaceY="32"
            :align="'center'"
            :count="3"
            :smCount="1"
            :wrap="true"
            v-if="lists.length">
            <li v-for="item of lists" :key="item.id">
              <router-link :to="`/interview/${item.open_id}/`" :class="s.itemLink">
                <ContentCard
                  :img="item.medias[0]?.url"
                  :imgRatio="70"
                  :smImgRatio="70"
                  :date="formatTimestamp(item.interview_date, 'YYYY.MM.DD')"
                  :name="item.title"
                  :content="item.summary"
                  :contentBg="'wht'"
                  :radius="28">
                  <spacer :y="1"/>
                  <FlexList
                    :spaceX="12"
                    :spaceY="12"
                    :wrap="true">
                    <router-link
                      :to="`/interview/?tag=${tag.id}`"
                      v-for="tag in item.tags" :key="tag.id">
                      <tag-btn
                        :size="'sm'"
                        :type="'category'">{{ tag.label }}</tag-btn>
                    </router-link>
                  </FlexList>
                </ContentCard>
              </router-link>
            </li>
          </FlexList>
          <div v-else-if="!flag.error" :class="s.error"><span>該当の移住者インタビューがありませんでした</span></div>
          <div v-else :class="s.error"><span>移住者インタビューの読み込みに失敗しました</span></div>
        </Loader>
        <Spacer :y="8" :smY="6"/>
        <FlexList :align="'center'">
          <li><basic-btn :link="'/interview/'" :tag="'a'">もっと見る</basic-btn></li>
        </FlexList>
      </SectionFrame>
    </SectionContainer>
    <Spacer :y="18" :smY="15"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import FlexList from './FlexList.vue';
import ContentCard from './ContentCard.vue';
import SectionContainer from './SectionContainer.vue';
import SectionFrame from './SectionFrame.vue';
import BasicBtn from './BasicBtn.vue';
import Spacer from './Spacer.vue';
import TagBtn from './TagBtn.vue';
import TagList from './TagList.vue';
import Loader from './Loader.vue';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'interview',
  mixins: [cf],
  props: {
    category: {
      type: Number,
    },
  },
  components: {
    FlexList,
    ContentCard,
    SectionContainer,
    SectionFrame,
    BasicBtn,
    Spacer,
    TagBtn,
    TagList,
    Loader,
  },
  data() {
    return {
      flag: {
        loaded: false,
        error: false,
      },
      lists: [],
      tags: [],
      selectedTags: {},
    };
  },
  created() {
    this.getList();
    this.getTags();
  },
  computed: {
    ...mapState(['helper']),
    categoryId() {
      return this.category;
    },
    adjustSelectedTags() {
      return Object.values(this.selectedTags).flat();
    },
    adjustedTags() {
      const tags = this.helper.master.tag?.types || [];
      tags.forEach((tagType) => {
        tagType.list = this.tags.filter((tag) => tag.type === tagType.id);
      });
      return tags;
    },
  },
  watch: {
    selectedTags: {
      handler() {
        this.flag.loaded = false;
        this.getList();
      },
      deep: true, // オブジェクトの中身まで監視
    },
    categoryId() {
      this.flag.loaded = false;
      this.getList();
      this.getTags();
    },
  },
  methods: {
    getList() {
      if (this.flag.loaded) return;

      const params = {
        flags: [1],
        limit: 3,
        tags: Object.values(this.selectedTags),
        orderBy: 'order',
        order: 'asc',
        categories: this.category ? [this.category] : null,
      };

      if (!this.category) return;

      this.axios({
        method: 'GET',
        url: '/v1/interview/get/list/',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.lists = res.interviews.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
          this.flag.error = true;
        })
        .finally(() => {
          this.flag.loaded = true;
        });
    },
    getTags() {
      const params = {
        isUsedInInterview: 1,
      };
      if (this.category) params.categoryId = this.category;
      this.axios({
        type: 'GET',
        url: '/v1/tag/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.tags = res.tags.data;
          if (this.$refs.tagList) {
            this.$refs.tagList[0].checkTagListWidth();
          }
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        });
    },
    clickSearchTags(tag) {
      const tagType = tag.type;
      if (this.selectedTags[tagType] && Array.isArray(this.selectedTags[tagType])) {
        if (this.selectedTags[tagType].includes(tag.id)) {
          this.selectedTags[tagType].splice(this.selectedTags[tagType].indexOf(tag.id), 1);
        } else {
          this.selectedTags[tagType].push(tag.id);
        }
      } else {
        this.selectedTags[tagType] = [tag.id];
      }

      if (!this.selectedTags[tagType].length) {
        delete this.selectedTags[tagType];
      }
    },
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  position: relative;
  background-color: #F8EEF5;
  .arc {
    width: 100%;
  }
}

.inner {
  position: relative;
  z-index: 2;
}

.gradation {
  &_wrap {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1;
  }
  width: 100%;
  padding-top: calc(100% * 216 / 390);
  background: linear-gradient(0deg, #FFF 2.22%, rgba(255, 255, 255, 0.00) 100%);
}

.itemLink {
  display: block;
  height: 100%;
  > * {
    height: 100%;
  }
}

.tag_list {
  width: fit-content;
  margin: 0 auto;
  > li {
    width: fit-content;
    min-width: 100%;
    &:not(:first-child) {
      margin-top: 12px;
    }
  }
}
.tag_group_name {
  padding: 4px 12px;
  font-size: 14px;
  margin-bottom: 12px;
  color: var(--label-secondary);
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.05);
  mix-blend-mode: multiply;
}
.tag_group_link {
  font-size: 14px;
  margin-bottom: 12px;
  > a {
    color: var(--label-primary);
    text-decoration: underline;
  }
}

.error {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  > * {
    padding: 8px 32px;
    display: inline-block;
    margin: 0 auto;
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    opacity: .8;
  }
}
</style>
